import React, { useState, useMemo,useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import "./Header.css";


// icons
import { FaBars, FaSearch } from "react-icons/fa";
import { IconContext } from "react-icons/lib";

import Images from "../../../assets/uploaded_images.json";

import PlacesAutocomplete from "react-places-autocomplete";

import { filtersProperty, geocodeAddressLocation } from "../../../features/property/propertySlice";
import SideBarMenu from "./HeaderSideBar";
import { LangSwitcher } from "../../common/LangSwitcher/LangSwitcher";
import { useTranslation } from "react-i18next";
import { validateInput } from "./../../../helpers/userInputEscaper";
import { toast } from "react-toastify";
import OverLaySearchBox from "./../../common/search/Dialog/Dialog"
import {getUserLang} from '../../../hooks/useTranslator';
import { supportedLanguages } from "../../common/search/SupportedLanguages";

const { useStyles }=require("./styles");
const AddIconProperty = Images["add-property-icon.svg"];
const Logo = Images["Logo.svg"];

const Header = () => {
  const { t } = useTranslation();
  const colorValue = useMemo(() => ({ color: "#fff" }), []);
  const classes = useStyles();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [isLangSupported,setIsLangSupported] = useState(false)
  const userLanguage = getUserLang()

  const handleSelect =async (value) => {
      let resultAddress = await geocodeAddressLocation(value);
      if (
        (!resultAddress?.formatted_address &&
        !resultAddress?.latitude &&
        !resultAddress?.longitude &&
        !resultAddress?.municipality &&
        !resultAddress?.region) ||
        !resultAddress?.continent
      ) {
        toast.error("Please choose another suitable and accurate address");
      } else {
        let newSearchQuery = {
          address: value,
        };
        if (resultAddress?.municipality) {
          newSearchQuery.municipality = resultAddress?.municipality;
        }
        if (resultAddress?.region) {
          newSearchQuery.region = resultAddress?.region;
        }
        if (resultAddress?.continent) {
          newSearchQuery.continent = resultAddress?.continent;
        }
        if (resultAddress?.country) {
          newSearchQuery.country = resultAddress?.country;
        }

  
        dispatch(filtersProperty(newSearchQuery));


        setAddress("");

        navigate("/search-properties");

      }
  };

  const handleClick = () => {
    navigate("/search-properties");
  };

  useEffect(()=>{
  const supportedLanguage = supportedLanguages.find((lang)=>lang.code===userLanguage)
  if(supportedLanguage){
    setIsLangSupported(true)
  }
  },[userLanguage])


  return (
    <header className="header-without-login">
      <IconContext.Provider value={colorValue}>
        <nav className="navbar">
          <div className="nav-items-mobile">
            <Link to="/" className="navbar-logo">
              <img loading="lazy" src={Logo} alt="" />
            </Link>

            <div className="display-translation-switcher">
                  <LangSwitcher />
                </div>

            <div className="nav-items-mobile-menu">
              <SideBarMenu />
            </div>
          </div>
          <div className="clearfix nav-items" style={{ width: "100%" }}>
            <Link to="/" className="navbar-logo">
              <img loading="lazy" src={Logo} alt="" />
            </Link>
            <div className="display-translation-switcher">
              <LangSwitcher />
            </div>

            <div className="menu-icon">
              <FaBars style={{ color: "#000" }} />
            </div>

            <ul className="nav-menu">
              <li className="nav-item">
                <LangSwitcher />
              </li>

              <li className="nav-item">
                <Link to="/" className="nav-links">
                  {t("Начало")}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/third-party?lawyers" className="nav-links">
                  {" "}
                  {t("Адвокати")}{" "}
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/third-party?virtualAssistants" className="nav-links">
                  {" "}
                  {t("Виртуални асистенти")}{" "}
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/third-party?photographers" className="nav-links">
                  {" "}
                  {t("Фотографи")}{" "}
                </Link>
              </li>
             {isLangSupported&&<li className="nav-item">
              <OverLaySearchBox/>
              </li>}
            </ul>
            {!isLangSupported&&<form className="search">
              <div className="search-places-auto-complete-container">
                <PlacesAutocomplete
                  value={validateInput(address) || ""}
                  onChange={setAddress}
                  onSelect={handleSelect}
                  classNames={{
                    dropdownContainer: "search-autocomplete-dropdown",
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <FaSearch
                        className={classes.faSearch}
                        style={{ color: "#0097A7" }}
                        onClick={handleClick}
                      />
                      <input
                        className="search-field"
                        {...getInputProps({
                          placeholder: `${t("Търси имоти")}`,
                        })}
                        style={{
                          padding: "20px",
                          fontSize: "16px",
                          color: "#000",
                          width: "100%",
                          border: "none",
                          borderRadius: "8px",
                        }}
                      />
                      <div
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        }}
                        className="search-autocomplete-dropdown"
                      >
                        {loading ? <div>...loading</div> : null}

                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#00BCD4"
                              : "#ffffff",
                            fontSize: "18px",
                            color: suggestion.active ? "#ffffff" : "#00BCD4",
                            padding: "5px",
                            cursor: "pointer",
                          };

                          return (
                            <div
                              key={suggestion.placeId}
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              </form>}

            <div className="right-part">
              <Link
                to="/login"
                className="add-property clearfix"
                style={{ cursor: "pointer" }}
              >
                <img loading="lazy" src={AddIconProperty} alt="" />

                <p className="add-text">{t("Добави имот")}</p>
              </Link>

              <Link to="/login" className="login-button">
                {" "}
                {t("Вход")}{" "}
              </Link>
              <Link to="/registration-about" className="registration-button">
                {" "}
                {t("Регистрация")}{" "}
              </Link>

              <SideBarMenu />
            </div>
            <div></div>
          </div>
        </nav>
      </IconContext.Provider>
    </header>
  );
};

export default Header;
