import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import "./ProgressBar.css"

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress sx={{
          height:15,
          borderRadius:20
        }}  variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography fontSize={20} variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
const mapState = ({ progressSlice }) => ({
    uploadProgress: progressSlice?.uploadProgress,
    isProgressBarVisible: progressSlice?.isProgressBarVisible,
  });
  
export default function ProgressBar() {
    const { uploadProgress, isProgressBarVisible } = useSelector(mapState);
  return isProgressBarVisible ? (
    <div  className="main-container">
    <div className='progress-bar-container'>

    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={uploadProgress} />
    </Box>
      </div>
    </div>
  ) : (
    ""
  );
}
