import React from "react";
import ReactDom from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import Favicon from "react-favicon";
import Images from "./assets/uploaded_images.json"

import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import store from "./store.js";
import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n";
const FaviconPNG = Images["favicon.png"];

const root =  ReactDom.createRoot(document.getElementById("root"))

root.render(
	<Provider store={store}>
		<Favicon url={FaviconPNG} />
		<BrowserRouter>
			<I18nextProvider i18n={i18n}>
				<App />
			</I18nextProvider>
		</BrowserRouter>
	</Provider>
);
