import axios from 'axios'

const userLang= localStorage.getItem("userLang") || navigator.language;

const baseUrl_Production = process.env.REACT_APP_BASEURL_PRODUCTION ?? 'https://api.abidors.com/'
const baseUrl_Develop = process.env.REACT_APP_BASEURL_DEVELOP ?? 'https://devapi.abidors.com/'

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? baseUrl_Develop : baseUrl_Production,
  // timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': userLang,
  }
});

export default instance