/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Images from "../../../assets/uploaded_images.json";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./HeaderBuyer.css";

import { Button } from "@material-ui/core";

import { useTranslation } from "react-i18next";

/* icons */
import { FaSearch } from "react-icons/fa";
import { IconContext } from "react-icons/lib";

import PlacesAutocomplete from "react-places-autocomplete";

/* svgs */

import {
  filtersProperty,
  geocodeAddressLocation,
} from "../../../features/property/propertySlice";
import {
  notifications,
  readNotifications,
} from "../../../features/user/authSlice";

import { buyerRedirectNotification } from "../../../features/user/buyerSlice";

import SideBarMenu from "./HeaderSideBar";
import { LangSwitcher } from "../../common/LangSwitcher/LangSwitcher";
import Notifications from "./../../common/Notifications/Notification";
import {
  listServiceRequests,
  resetServiceRequest,
} from "../../../features/common/serviceRequestSlice";

import { handleLogout, handleListofSentOffers } from "./functions";
import { validateInput } from "./../../../helpers/userInputEscaper";
import { toast } from "react-toastify";
import { getLocalStorageItem } from "../../../config/localStorageEncryption";
const { useStyles } = require("./styles");
const Account = Images["account-header.svg"];
const Vector = Images["vector.svg"];
const Deal = Images["deal.svg"];
const Logo = Images["Logo.svg"];

const HeaderBuyer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const location = useLocation();

  const messageRef = useRef(null);

  const storeAddress = useSelector(
    (state) => state.propertySlice?.filters?.address
  );
  const personaId = getLocalStorageItem("activePersonaDetails")?.buyerPersonaId;

  const [address, setAddress] = useState("");
  const [openNotificaration, setOpenNotification] = useState(false);

  const colorValue = () => ({ color: "#fff" });

  useEffect(() => {
    if (location?.pathname !== "/search-properties") {
      if (storeAddress) {
        dispatch(
          filtersProperty({
            municipality: "",
            address: "",
          })
        );
      }
    }
  }, [location]);


  useEffect(() => {
    dispatch(buyerRedirectNotification(""));

    const checkIfClickedOutside = (e) => {
      if (
        openNotificaration &&
        messageRef.current &&
        !messageRef.current.contains(e.target)
      ) {
        setOpenNotification(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [openNotificaration]);

  /* Handle click search icon */
  const handleClick = () => {
    navigate("/search-properties");
  };

  /* Handle select cities */
  const handleSelect = async (value) => {
    let resultAddress = await geocodeAddressLocation(value);
    if (
      (!resultAddress?.formatted_address &&
        !resultAddress?.latitude &&
        !resultAddress?.longitude &&
        !resultAddress?.municipality &&
        !resultAddress?.region) ||
      !resultAddress?.continent
    ) {
      toast.error("Please choose another suitable and accurate address");
    } else {
      let newSearchQuery = {
        address: value,
      };
      if (resultAddress?.municipality) {
        newSearchQuery.municipality = resultAddress?.municipality;
      }
      if (resultAddress?.region) {
        newSearchQuery.region = resultAddress?.region;
      }
      if (resultAddress?.continent) {
        newSearchQuery.continent = resultAddress?.continent;
      }
      if (resultAddress?.country) {
        newSearchQuery.country = resultAddress?.country;
      }
      setAddress("");

      dispatch(filtersProperty(newSearchQuery));

      navigate("/search-properties");
    }
  };

  const handleClickNotification = (item) => {
    let redirectUrl = "";

    if (item?.notificationType === "rejectedOffer") {
      redirectUrl = "/buyer/buyer-my-profile";
    } else if (item?.notificationType === "acceptedOffer") {
      redirectUrl = "/buyer/buyer-my-profile?my-offer";
    } else if (item?.notificationType === "receivedBadge") {
      redirectUrl = "/search-properties";
    } else if (item?.notificationType === "expiredOffer") {
      redirectUrl = "/buyer/buyer-my-profile";
    } else if (item?.notificationType === "foreclosedAnotherBuyer") {
      redirectUrl = "/buyer/buyer-my-profile";
    } else if (item?.notificationType === "foreclosedAvailableAgain") {
      redirectUrl = "/buyer/buyer-my-profile";
    } else if (item?.notificationType === "acceptedViewingRequest") {
      handleListofSentOffers(item, dispatch, location);
    } else if (item?.notificationType === "sellerChangeDateViewing") {
      handleListofSentOffers(item, dispatch, location);
    } else if (item?.notificationType === "finalSaleAmount") {
      handleListofSentOffers(item, dispatch, location);
    } else if (item?.notificationType === "signedPrecontract") {
      handleListofSentOffers(item, dispatch, location);
    } else if (item?.notificationType === "lawyerAcceptedInquiry") {
      dispatch(
        listServiceRequests({ customerId: personaId, customerType: "buyer" })
      );
      dispatch(resetServiceRequest());
      redirectUrl = "/buyer/buyer-my-profile?" + item?.senderPersona + "s";
    } else if (item?.notificationType === "lawyerAcceptedPayment") {
      dispatch(
        listServiceRequests({ customerId: personaId, customerType: "buyer" })
      );
      dispatch(resetServiceRequest());
      redirectUrl = "/buyer/buyer-my-profile?" + item?.senderPersona + "s";
    } else if (item?.notificationType === "lawyerMarkPerformed") {
      dispatch(
        listServiceRequests({ customerId: personaId, customerType: "buyer" })
      );
      dispatch(resetServiceRequest());
      redirectUrl = "/buyer/buyer-my-profile?" + item?.senderPersona + "s";
    } else if (item?.notificationType === "approvedPayCommission") {
      handleListofSentOffers(item, dispatch, location);
    } else if (
      item?.notificationType === "lawyerRejectedJobRequest" ||
      item?.notificationType === "lawyerCancelledJobRequest" ||
      item?.notificationType === "vaRejectedJobRequest" ||
      item?.notificationType === "vaCancelledJobRequest"
    ) {
      if (item?.senderPersona === "lawyer") {
        redirectUrl =
          "/third-party/lawyer-profile/" + item?.senderActivePersonaId;
      }

      if (item?.senderPersona === "photographer") {
        redirectUrl =
          "/third-party/photographer-profile/" + item?.senderActivePersonaId;
      }
      if (item?.senderPersona === "virtualassistant") {
        redirectUrl =
          "/third-party/virtual-assistant-profile/" +
          item?.senderActivePersonaId;
      }
    }

    dispatch(readNotifications(item?.notificationId)).then((r) => {
      if (r?.payload?.data?.status) {
        setOpenNotification(false);
        dispatch(notifications());
      }
    });

    dispatch(buyerRedirectNotification(redirectUrl));
  };

  return (
    <header className="header-buyer">
      <IconContext.Provider value={colorValue}>
        <nav className="navbar">
          <div className="clearfix" style={{ width: "100%" }}>
            <Link to="/buyer" className="navbar-logo">
              <img loading="lazy" src={Logo} alt="" />
            </Link>

            <ul className="nav-menu">
              <li className="nav-item-lang-switcher">
                <LangSwitcher />
              </li>
              <li className="nav-item">
                <Link to="/buyer" className="nav-links">
                  {t("Начало")}
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/third-party?lawyers" className="nav-links">
                  {" "}
                  {t("Адвокати")}{" "}
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/third-party?virtualAssistants" className="nav-links">
                  {" "}
                  {t("Виртуални асистенти")}{" "}
                </Link>
              </li>
            </ul>

            <form className="search">
              <PlacesAutocomplete
                value={validateInput(address) || ""}
                onChange={setAddress}
                onSelect={handleSelect}
                className="search"
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <FaSearch
                      className={classes.faSearch}
                      style={{ color: "#0097A7" }}
                      onClick={handleClick}
                    />
                    <input
                      className="search-field"
                      {...getInputProps({ placeholder: t("Търси имоти") })}
                      style={{
                        padding: "20px",
                        fontSize: "16px",
                        color: "#000",
                        width: "100%",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    />

                    <div
                      style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                    >
                      {loading ? <div>...loading</div> : null}

                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#00BCD4"
                            : "#ffffff",
                          fontSize: "18px",
                          color: suggestion.active ? "#ffffff" : "#00BCD4",
                          padding: "5px",
                          cursor: "pointer",
                        };

                        return (
                          <div
                            key={suggestion.placeId}
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </form>

            <div className="right-part">
              {location?.pathname === "/dashboard" ||
              location?.pathname === "/questioneery" ? (
                <div className="account-group">
                  <Link to="/" className="clearfix link">
                    <img loading="lazy" src={Deal} alt="" />

                    <p className="title">{t("Сделка")}</p>
                  </Link>
                </div>
              ) : null}

              <div className="account-group">
                <Link to="/buyer/buyer-my-profile" className="clearfix link">
                  <img loading="lazy" src={Vector} alt="" />

                  <p className="title">{t("финансиране")}</p>
                </Link>
              </div>
              <div className="right-part-mobile">
                <div className="buyer-lang-switcher">
                  <LangSwitcher />
                </div>
                <div className="notification-icon">

                <Notifications
                  handleNotification={handleClickNotification}
                  setOpenNotification={setOpenNotification}
                  openNotificaration={openNotificaration}
                  messageRef={messageRef}
                />
                </div>

                <div className="bell user-account">
                  <Link to="/buyer/buyer-my-profile?my-offer">
                    <img loading="lazy" src={Account} className="icon" alt="" />
                  </Link>
                </div>

                <Button className="logout-button" onClick={handleLogout}>
                  {" "}
                  {t("Изход")}{" "}
                </Button>
                <div className="sidebar-menu-icon">
                <SideBarMenu />
                </div>
              </div>
            </div>
          </div>
        </nav>
      </IconContext.Provider>
    </header>
  );
};

export default HeaderBuyer;
