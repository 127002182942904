import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/* icons */
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import { LinkedIn } from "@material-ui/icons";
import Images from "../../assets/uploaded_images.json";
import { multiprofileDetails } from "../../features/common/mutiprofile.Slice";
import { filtersProperty } from "../../features/property/propertySlice";
import PrintResults from "./components/printResults";

import "./Footer.css";
import { getUserLocationDetailByName } from "../../hooks/useTranslator";
import { useSelector } from "react-redux";
import { getLocalStorageItem } from "../../config/localStorageEncryption";
import instance from "../../config/axiosConfig";
const Logo = Images["Group 185.svg"];

const mapState = ({ statisticsSlice }) => ({
  sentOffers: statisticsSlice.sentOffers,
  listedProperties: statisticsSlice.listedProperties,
});

const Footer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sentOffers, listedProperties } = useSelector(mapState);
  const [
    userCountryCitiesWithNoProperties,
    setUserCountryCitiesWithNoProperties,
  ] = useState([]);
  const [residences, setResidences] = useState([]);

  const year = new Date().getFullYear();

  const activePersona = getLocalStorageItem("authenticatedUser")
    ? getLocalStorageItem("authenticatedUser")?.activePersona
    : null;

  const activePersonaDetails = getLocalStorageItem("activePersonaDetails")
    ? getLocalStorageItem("activePersonaDetails")
    : "";

  const handleRedirect = (value) => {
    dispatch(
      filtersProperty({
        municipality: value,
        address: value,
        neighborhood: "",
      })
    );

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    navigate("/search-properties");
  };
  const getUserRecommendations = () => {
    getUserLocationDetailByName("countryName").then(async (userCountry) => {
      let userCountryCities =
        userCountry === "Bulgaria"
          ? ([
              "Sofia",
              "Plovdiv",
              "Ruse",
              "Stara Zagora",
              "Pleven",
              "Varna",
              "Burgas",
              "Sliven",
              "Dobrich",
              "Shumen",
            ])
          : await getUserLocationDetailByName("userCountryCities");

      instance
        .post("/configurations/get-city-recommendations", {
          cities: userCountryCities,
        })
        .then(async ({ data }) => {
          let recommendedCities = data.citiesAndPropertyCounts;
          setResidences(recommendedCities);
          if (recommendedCities?.length === 0) {
            userCountryCities?.slice(0, 10).forEach((city) => {
              userCountryCities.push({
                city,
                propertyCount: 0,
              });
            });
            setUserCountryCitiesWithNoProperties(userCountryCities);
          }
        });
    });
  };
  useEffect(() => {
    getUserRecommendations();
  }, []);

  const handleOpenMultiProfile = () => {
    dispatch(
      multiprofileDetails({
        isOpenModal: true,
      })
    );
  };

  return (
    <footer id="footer" className="footer">
      <div className="footer-container">
        <div className="container">
          <div className="row  row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4">
            <div className="col">
              <img loading="lazy" src={Logo} alt="" />
              <p className="paragraph">
                {" "}
                {t("Всички права запазени")} {year}
              </p>

              <div className="footerIcons">
                <a
                  href="https://www.facebook.com/abidorsbrand"
                  style={{ color: "#fff" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon className="icon" />
                </a>

                <a
                  href="https://www.instagram.com/abidorsofficial"
                  style={{ color: "#fff" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon className="icon" />
                </a>

                <a
                  href="https://www.linkedin.com/company/abidors"
                  style={{ color: "#fff" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedIn className="icon" />
                </a>
              </div>
            </div>

            <div className="col">
              <h3 className="footerHeaderTitles">{t("Полезни линкове")}</h3>
              <Link to="/general-terms" className="text">
                {" "}
                {t("Общи условия")}{" "}
              </Link>
              <Link to="/privacy-policy" className="text">
                {" "}
                {t("Политика за сигурност")}{" "}
              </Link>
              <Link to="/Cookies" className="text">
                {" "}
                {t("Политика за бисквитки")}{" "}
              </Link>
              <Link to="/about" className="text">
                {" "}
                {t("За нас")}{" "}
              </Link>
              <Link to="/pricing" className="text">
                {" "}
                {t("Ценообразуване")}{" "}
              </Link>
              <Link to="/how-to-buy" className="text">
                {" "}
                {t("Как да закупя имот?")}{" "}
              </Link>
              <Link to="/how-to-sell" className="text">
                {" "}
                {t("Как да продам имот?")}{" "}
              </Link>
              {activePersona !== "virtualassistant" &&
              activePersona !== "virtualAssistant" ? (
                <div className="button-group mb-4 mt-md-2 mb-md-5 mb-sm-4">
                  <Button
                    className="change_persona_button w-md-50 w-20"
                    onClick={handleOpenMultiProfile}
                  >
                    {t("Стани виртуален асистент")}
                  </Button>
                </div>
              ) : (
                <div className="button-group mb-4 mt-md-2 mb-md-5 mb-sm-4">
                  <Button
                    className="button w-md-50 w-20"
                    onClick={handleOpenMultiProfile}
                  >
                    {t("Мулти-профил")}
                  </Button>
                </div>
              )}
            </div>

            <div
              className="col"
              style={{
                pointerEvents:
                  (activePersona === "virtualassistant" ||
                    activePersona === "virtualAssistant") &&
                  !activePersonaDetails?.isCertified
                    ? "none"
                    : "auto",
                userSelect:
                  (activePersona === "virtualassistant" ||
                    activePersona === "virtualAssistant") &&
                  !activePersonaDetails?.isCertified
                    ? "none"
                    : "auto",
              }}
            >
              <h3 className="footerHeaderTitles">
                {residences?.length > 0
                  ? t("Намерете имоти в")
                  : t("Очаквайте скоро имоти в:")}
              </h3>
              <PrintResults
                array={
                  residences?.length > 0
                    ? residences
                    : userCountryCitiesWithNoProperties
                }
                handleRedirect={handleRedirect}
              />
            </div>
            <div className="col">
              <h3 className="footerHeaderTitles">
                {t("Световна статистика от стартирането на системата")}
              </h3>
              <p className="text">
                <strong>{sentOffers} </strong>
                {sentOffers > 1
                  ? `${t("Изпратени оферти")}`
                  : `${t("Изпратена оферта")}`}
              </p>
              <p className="text">
                <strong>{listedProperties}</strong> {t("Публикувани")}{" "}
                {sentOffers !== 1 ? `${t("имота")}` : `${t("имот")}`}
              </p>

              <Button
                className="button w-md-50 w-auto"
                onClick={() => navigate("/search-properties")}
              >
                {t("Търсене на имот")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
