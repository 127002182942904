import { Dialog, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { propertyViewDetails } from "../../../features/common/propertyViewSlice";
import MasonryGallery from "./MasonryGallery/MasonryGallery";
import Images from "../../../assets/uploaded_images.json";
import { t } from "i18next";
import "./PropertyViewModal.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import GoogleMapReact from "google-map-react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
const { useStyles } = require("./styles");

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const mapState = ({ propertyViewSlice }) => ({
  isOpenModal: propertyViewSlice?.isOpenModal,
  activeProperty: propertyViewSlice?.activeProperty,
});

const CloseModal = Images["close-modal.svg"];
const AbidorsPin = Images["abidorsPin.png"];
const DefaultImage = Images["common-image.jpg"];

function PropertyViewModal() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [propertyImages, setPropertyImages] = useState([]);
  const [propertyVideo, setPropertyVideo] = useState();
  const handleClose = () => {
    dispatch(
      propertyViewDetails({
        isOpenModal: false,
      })
    );
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { isOpenModal, activeProperty } = useSelector(mapState);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /* Tooltip title */
  const title = () => {
    let mappingPropertyMedia = [];

    activeProperty?.propertyMedia?.map((item) =>
      item?.type === "image" ? mappingPropertyMedia.push(item) : null
    );

    return (
      <div className="modal-marker-details">
        <img
          src={
            mappingPropertyMedia?.length
              ? mappingPropertyMedia[0]?.documentUrl
              : DefaultImage
          }
          alt=""
        />
      </div>
    );
  };

  /* CENTER MAP */
  const [center, setCenter] = useState({});

  /* Custom marker */
  const Marker = () => {
    return (
      <Tooltip
        enterTouchDelay={0}
        classes={{ tooltip: classes.customTooltip }}
        title={title()}
      >
        <div
          className="marker"
          style={{
            backgroundImage: `url(${AbidorsPin})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            width: "50px",
            height: "50px",
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
        />
      </Tooltip>
    );
  };

  const defaultCenter = {
    lat: 42.69751,
    lng: 23.32415,
  };
  const optionsZoom = {
    maxZoom: 15,
    mapTypeControl: false,
  };

  /* ZOOM MAP */
  const zoom = 10;

  useEffect(() => {
    let newVal = defaultCenter;

    if (activeProperty?.locationLatitude && activeProperty?.locationLongitude) {
      newVal = {
        lat: Number(activeProperty?.locationLatitude),
        lng: Number(activeProperty?.locationLongitude),
      };
    }

    let images = activeProperty?.propertyMedia
      ?.map((media) => {
        if (media.type === "image") {
          return media?.documentUrl;
        }
      })
      ?.filter((media) => media && media);
    setPropertyImages(images);
    let propertyVideo = activeProperty?.propertyMedia?.find(
      (media) => media.type === "video"
    )?.documentUrl;
    setPropertyVideo(propertyVideo);
    setCenter(newVal);
  }, [isOpenModal]);
  return (
    <Dialog
      disableScrollLock={false}
      onClose={handleClose}
      open={isOpenModal}
      fullWidth={fullScreen}
      fullScreen={fullScreen}
      maxWidth={fullScreen ? false : "xl"}
      PaperProps={{
        style: {
          width: "100%",
          background: "#FFFFFF",
          boxShadow: "0px 4px 32px rgba(0, 0, 0, 0.25)",
          borderRadius: fullScreen ? "0px" : "10px",
          padding: fullScreen ? "0px" : "10px",
        },
      }}
    >
      <div className="dialog-property-view-modal-content">
        <div className="title-header">
          <h4 className="title">{t("Изглед към имота")}</h4>
          <div className="close-icon" onClick={handleClose}>
            <CloseIcon htmlColor="black" fontSize="15px" />
          </div>
        </div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={t("Images")} {...a11yProps(0)} />
              <Tab label={t("Video")} {...a11yProps(1)} />
              <Tab label={t("Map")} {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <MasonryGallery images={propertyImages} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {propertyVideo ? (
              <video
                className="videoContainer"
                src={propertyVideo}
                style={{ height: "100%", width: "100%" }}
                controls
              />
            ) : (
              <div
                style={{ minHeight: "100%", minWidth: "100%", display: "flex" }}
              >
                {t("Няма наличен видеозапис на имота")}
              </div>
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <div className="maps">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                }}
                center={center}
                language="bg"
                defaultZoom={zoom}
                options={optionsZoom}
              >
                <Marker
                  lat={activeProperty?.locationLatitude}
                  lng={activeProperty?.locationLongitude}
                  name={t("Приблизителна локация")}
                />
              </GoogleMapReact>
            </div>
          </CustomTabPanel>
        </Box>
      </div>
    </Dialog>
  );
}

export default PropertyViewModal;
