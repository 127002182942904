import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uploadProgress: 0,
  isProgressBarVisible: false,
};

export const progressSlice = createSlice({
  name: "progressSlice",
  initialState,
  reducers: {
    setUploadProgress: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setUploadProgress } = progressSlice.actions;

export default progressSlice.reducer;
