import { Dialog } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { propertyViewDetails } from "../../../../features/common/propertyViewSlice";
import Images from "../../../../assets/uploaded_images.json";
import "./PropertyImageViewModal.css";
import CloseIcon from "@mui/icons-material/Close";
const CloseModal = Images["close-modal.svg"];
const mapState = ({ propertyViewSlice }) => ({
  isViewingImageModalOpen: propertyViewSlice?.isViewingImageModalOpen,
  activePropertyImage: propertyViewSlice?.activePropertyImage,
});
function PropertyImageViewModal() {
  const dispatch = useDispatch();
  const { isViewingImageModalOpen, activePropertyImage } =
    useSelector(mapState);
  const handleClose = () => {
    dispatch(
      propertyViewDetails({
        isViewingImageModalOpen: false,
        activePropertyImage: null,
      })
    );
  };
  return (
    <Dialog
      disableScrollLock={true}
      onClose={handleClose}
      open={isViewingImageModalOpen}
      fullWidth={true}
      fullScreen={true}
      PaperProps={{
        style: {
          background: "#FFFFFF",
          boxShadow: "0px 4px 32px rgba(0, 0, 0, 0.25)",
          width: "100%",
        },
      }}
    >
      <div className="dialog-property-image-view-modal-content">
        <div className="close-icon" onClick={handleClose}>
          <CloseIcon htmlColor="black" fontSize="15px" />
        </div>
        <img
          style={{
            objectFit: "contain",
            display: "flex",
            width: "100%",
            height: "100%",
          }}
          src={activePropertyImage}
        />
      </div>
    </Dialog>
  );
}

export default PropertyImageViewModal;
