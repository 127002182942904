import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../config/axiosConfig';
import Cookie from 'js-cookie';
import { toast } from 'react-toastify';
import { getUserLocationDetailByName } from '../../hooks/useTranslator';
import { getLocalStorageItem, setLocalStorageItem } from '../../config/localStorageEncryption';
import { jsonToQueryString } from '../../utils';

const initialState = {
	value: 0,
	entities: [],
	email: undefined,
	password: undefined,
	loading: 'idle',
	error: null,
	currentUser: {},
	currentUserList: [],
	lawyerPersonaList: [],
	photographerPersonaList: [],
	virtualAssistantPersonaList: [],
	notifications: {
		isLoadingNotifications:false,
		currentPage:null,
		notificationsList:[],
		totalPages:null
	},
	isUserLocationAddressAvailable:false
}

export const loginUser = createAsyncThunk(
	'user/loginUser',
	async (payload) => {
		try {
			const { email, password } = payload;
			const response = await instance.post(`/auth/login`, { email, password });
			const userToken = response?.data.token;
			const { userId, email: userEmail, activePersona, familyName, createdAt, isActive, name, phoneNumber, profilePicture, unsubscribedEmailNotifications,userCountry,userSocketIoRoomId } = response?.data.User;

			const activePersonaDetails = response?.data?.activePersonaDetails;
			const authenticatedUser = { userId, activePersona, userEmail, familyName, createdAt, isActive, name, phoneNumber, profilePicture, unsubscribedEmailNotifications,userCountry,userSocketIoRoomId };

			Cookie.set('userToken', userToken, { expires: 7 });
			Cookie.set('activePersona', activePersona);

			setLocalStorageItem('authenticatedUser', authenticatedUser)
			setLocalStorageItem('activePersonaDetails', activePersonaDetails)
			setLocalStorageItem('user', response?.data?.User)
			return response
		} catch (error) {
			toast.error(error?.response?.data?.message)
		}
	}
)

export const socialLoginRefreshToken = createAsyncThunk(
	'/socialAuth/refreshToken',
	async(payload)=>{
     try {
       
		const {userId,provider} =  payload
		let response;

		const body = {
			  userId,
			  provider
		}

		response =  await instance.post(`/socialAuth/refreshtoken`,body)
        
		return response

	 } catch (error) {

		return toast.error(error?.response?.data?.error)
	 }
	}
)

export const socialRegistration = createAsyncThunk(
	'user/socialRegistration',
	async(payload)=>{
     try {
       
		const {activePersona,connection} =  payload
		let response;

		const body = {
				activePersona:activePersona,
				isPrivacyPolicy:true,
				isTermsAndConditions:true,
				connection:connection
		}

		response =  await instance.post(`/socialAuth/login`,body)
        
		return response

	 } catch (error) {

		return toast.error(error?.response?.data?.error)
	 }
	}
)

export const registerUser = createAsyncThunk(
	'user/registerUser',
	async (payload) => {
		try {
			let response;
			const { userName, familyName, userEmail, password, userPhone, activePersona, checkedBoxTerms, checkedBoxTermss,recaptchatoken } = payload;
			let userCountry=await getUserLocationDetailByName("userCountry")
			if (getLocalStorageItem("virtualAssistantRefLink")) {
				response = await instance.post(
					`/user/register?ref=${getLocalStorageItem("virtualAssistantRefLink")}`, { name: userName, familyName: familyName, activePersona, email: userEmail, password, phoneNumber: userPhone, profilePicture: 'test', isPrivacyPolicy: checkedBoxTerms, isTermsAndConditions: checkedBoxTermss,userCountry },{
						headers:{
							recaptchatoken
						}
					}
				)
			} else {
				response = await instance.post(
					`/user/register`, { name: userName, familyName: familyName, activePersona, email: userEmail, password, phoneNumber: userPhone, profilePicture: 'test', isPrivacyPolicy: checkedBoxTerms, isTermsAndConditions: checkedBoxTermss,userCountry },{
						headers:{
							recaptchatoken
						}
					}
				)
			}

			return response
		} catch (error) {
			return toast.error(error?.response?.data?.error)
		}
	}
);

export const verifyUserAccount = createAsyncThunk(
	'user/verifyuseraccount',
	async (payload) => {

		try{
			const response = await instance.get(
				`/user/verification/${payload.activationToken}`,
			)

			return response

		}catch(error){
     toast.error(error?.response?.data?.message)
		}
	}
)

export const generateNewActivationLink = createAsyncThunk(
	'user/verifyuseraccount',
	async (payload) => {

		try{
			document.getElementById("loading-indicator").classList.add("open");

			const response = await instance.post(
				`/user/verification-generate-new-link/${payload.activationToken}`,
			)
			document.getElementById("loading-indicator").classList.remove("open");

			return response

		}catch(error){
     		toast.error(error?.response?.data?.message)
		}
	}
)
export const newEmailVerification = createAsyncThunk(
	'user/newEmailVerification',
	async (payload) => {
		try {
			const { isOldEmailRequest, ...body } = payload;
			const response = await instance.post(
				isOldEmailRequest ? `/user/verifyemail` : `/user/changeemail`, { ...body }
			)

			return response
		} catch (error) {
			toast.error(error?.response?.data?.message)
			return error
		}
	}
);

export const recoverpassword = createAsyncThunk(
	'user/recoverpassword',
	async (payload) => {
		try {
			const response = await instance.post(
				`/user/recoverpassword`, { email: payload }
			)

			return response
		} catch (error) {
			toast.error(error?.response?.data?.message)
		}
	}
);

export const resetpassword = createAsyncThunk(
	'user/resetpassword',
	async (payload) => {
		try {
			const response = await instance.post(
				`/user/resetpassword`, { resetPassToken: payload?.resetPassToken, password: payload?.password }
			)

			return response
		} catch (error) {
			toast.error(error?.response?.data?.message)
		}
	}
);

export const getUserById = createAsyncThunk(
	'user/CurrentUser',
	async (userId, thunkAPI) => {
		try {
			const token = Cookie.get('userToken')

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			const response = await instance.get(`/seller/profile-by-id/${userId}`, config)
			
			return response
		} catch (error) {
			// toast.error(error?.response?.data?.message)
		}
	}
);

export const getSellerByUserId = createAsyncThunk(
	'seller/profile-by-id',
	async (userId, thunkAPI) => {
		try {
			const token = Cookie.get('userToken')

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			const response = await instance.get(`/seller/profile-by-id/${userId}`, config)
			
			
			return response
		} catch (error) {
			// toast.error(error?.response?.data?.message)
		}
})
export const getBuyerByUserId = createAsyncThunk(
	'buyer/profile-by-id',
	async (userId, thunkAPI) => {
		try {
			const token = Cookie.get('userToken')

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			const response = await instance.get(`/buyer/profile-by-id/${userId}`, config)
			
			
			return response
		} catch (error) {
			// toast.error(error?.response?.data?.message)
		}
})

export const getCurrentUserList = createAsyncThunk(
	'user/CurrentUserList',
	async (_, thunkAPI) => {
		try {
			const token = Cookie.get('userToken')

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			const response = await instance.get(`/user/userlist`, config)
			return response.data;
		} catch (error) {
			toast.error(error?.response?.data?.message);
		}
	}
);

export const getCurrentLawyers = createAsyncThunk(
	'user/getCurrentLawyers',
	async (_, thunkAPI) => {
		try {
			const token = Cookie.get('userToken')

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			const response = await instance.get(`/lawyers?isActive=true`, config)
			return response.data;
		} catch (error) {
			toast.error(error?.response?.data?.message);
		}
	}
);

export const getCurrentPhotographers = createAsyncThunk(
	'user/getCurrentPhotographers',
	async (_, thunkAPI) => {
		try {
			const token = Cookie.get('userToken')

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			const response = await instance.get(`/photographers?isActive=true`, config)
			return response.data;
		} catch (error) {
			toast.error(error?.response?.data?.message);
		}
	}
);

export const getCurrentVirtualAssistants = createAsyncThunk(
	'user/getCurrentVirtualAssistants',
	async (_, thunkAPI) => {
		try {
			const token = Cookie.get('userToken')

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			const response = await instance.get(`/virtual-assistants?isActive=true`, config)
			return response.data;
		} catch (error) {
			toast.error(error?.response?.data?.message);
		}
	}
);


export const testNotiff = createAsyncThunk(
	'user/testNotiff',
	async (_, thunkAPI) => {
		try {
			const token = Cookie.get('userToken')

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			let userId = "";

			if (getLocalStorageItem("activePersonaDetails")) {
				userId = getLocalStorageItem("activePersonaDetails")?.userId;
			}

			const response = await instance.get(`/user/testfcm/${userId}`, config)
			return response.data;
		} catch (error) {
			toast.error(error?.response?.data?.message);
		}
	}
);



export const stripePaymentSetup = createAsyncThunk(
	'user/stripePaymentSetup',
	async (payload, thunkAPI) => {
		try {
			const token = Cookie.get('userToken')
			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			const { personaType, personaID } = payload;

			const response = await instance.get(
				`/${personaType}/${personaID}/stripe`, config
			)
			return response
		} catch (error) {
			toast.error(error?.response?.data?.message)
		}
	}
);

export const saveFCMtoken = createAsyncThunk(
	'user/saveFCMtoken',
	async (payload, thunkAPI) => {
		try {
			const token = Cookie.get('userToken');

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}
			const response = await instance.post(
				`/user/fcmtoken`, payload, config
			)
			return response
		} catch (error) {
			return toast.error(error?.response?.data?.message)
		}
	})

export const notifications = createAsyncThunk(
	'user/notifications',
	async (payload, thunkAPI) => {
		try {
			const token = Cookie.get('userToken');

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			const filters = payload?.filters ? payload?.filters : null;
			const parsedFilters = jsonToQueryString(filters);

			const response = await instance.get(
				payload?.all  ? `/notifications?${parsedFilters}` :
				`/notifications?page=${payload?.notificationsPage?payload?.notificationsPage:1}&size=10${parsedFilters ? `&${parsedFilters}`  : ''}`, config
			)
			return response
		} catch (error) {
			toast.error(error?.response?.data?.message)
		}
	}
);
export const notificationsPropertyStatusCheck= createAsyncThunk(
	'user/notifications-proeprty-status-check',
	async (payload, thunkAPI) => {
		try {
			const token = Cookie.get('userToken');

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			const filters = payload?.filters ? payload?.filters : null;
			const parsedFilters = jsonToQueryString(filters);

			const response = await instance.get(
				payload?.all  ? `/notifications?${parsedFilters}` :
				`/notifications?page=${payload?.notificationsPage?payload?.notificationsPage:1}&size=10${parsedFilters ? `&${parsedFilters}`  : ''}`, config
			)
			return response
		} catch (error) {
			toast.error(error?.response?.data?.message)
		}
	}
);

export const readNotifications = createAsyncThunk(
	'user/read/notifications',
	async (payload, thunkAPI) => {
		try {
			const token = Cookie.get('userToken');

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			let obj = {
				read: true
			}

			const response = await instance.put(
				`/notifications/${payload}`, obj, config
			)
			return response
		} catch (error) {
			toast.error(error?.response?.data?.message)
		}
	}
);

export const userUpdatePrefferedLanguage = createAsyncThunk(
	'user/userUpdatePrefferedLanguage',
	async (payload, thunkAPI) => {
		try {
			const token = Cookie.get('userToken');

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			const response = await instance.post(
				`/user/updateuserdetails`, {
				preferredLanguage: payload
			}, config
			)


			return response
		} catch (error) {
			toast.error(error?.response?.data?.message)
		}

	}
);

export const userUpdateDetails = createAsyncThunk(
	'user/userUpdateDetails',
	async (payload, thunkAPI) => {
		try {
			const token = Cookie.get('userToken');

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			const response = await instance.post(
				`/user/updateuserdetails`, payload, config
			)
			setLocalStorageItem('user', response?.data)
			return response

		} catch (error) {
			toast.error(error?.response?.data?.message)
		}

	}
);

export const personaUpdateDetails = createAsyncThunk(
	'user/personaUpdateDetails',
	async (payload, thunkAPI) => {
		try {
			const token = Cookie.get('userToken');

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			const response = await instance.post(
				`/user/updatepersonadetails`, payload, config
			)
			if(typeof response?.data === 'object') {
				setLocalStorageItem('activePersonaDetails', response?.data)
			}
			return response

		} catch (error) {
			toast.error(error?.response?.data?.message)
		}

	}
);

export const userDeactivatePersona = createAsyncThunk(
	'user/userDeactivatePersona',
	async (payload, thunkAPI) => {
		try {
			const token = Cookie.get('userToken');

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			const response = await instance.post(
				`/user/deactivatepersona`, payload, config
			)
			return response

		} catch (error) {
			toast.error(error?.response?.data?.message)
		}

	}
);

export const userGetPersonaStatus = createAsyncThunk(
	'user/userGetPersonaStatus',
	async (payload, thunkAPI) => {
		try {
			const token = Cookie.get('userToken');

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			}

			const response = await instance.get(
				`/user/getpersonastatus`, config
			)
			return response

		} catch (error) {
			toast.error(error?.response?.data?.message)
		}

	}
);

export const occasionalUpdateUserCountry = createAsyncThunk(
	'user/updateusercountry',
	async (payload, thunkAPI) => {
		try {
			let geoUserCountryLocation=await getUserLocationDetailByName("countryName");
			let authenticatedUserCountryLocation= getLocalStorageItem("authenticatedUser")
			? getLocalStorageItem("authenticatedUser")?.userCountry
			: null;

			if(authenticatedUserCountryLocation && geoUserCountryLocation !==authenticatedUserCountryLocation){

				const token = Cookie.get('userToken');
				
				const config = {
					headers: { Authorization: `Bearer ${token}` }
				}
				
				const response = await instance.post(
					`/user/updateuserdetails`, {
						userCountry: geoUserCountryLocation
					}, config
					)
					
					return response
					
			}
			else{
				return {
					noUdateNeed:true
				}
			}
		} catch (error) {
			toast.error(error?.response?.data?.message)
		}

	}
);

export const authSlice = createSlice({
	name: 'user',
	initialState,
	reducers:{
		changeIsUserLocationAddressAvailable:(state,action)=>{
			return {
				...state,
				isUserLocationAddressAvailable:action?.payload
			}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(registerUser.pending, (state, action) => {

				const { userName, userEmail, password, userPhone, activePersona } = action.meta.arg;

				if (state.loading === 'idle') {
					state.loading = 'pending'
					state.email = userEmail
					state.password = password
					state.activePersona = activePersona
					state.userPhone = userPhone
					state.userName = userName
				}
			})
			.addCase(registerUser.fulfilled, (state, action) => {

				const { userName, userEmail, activePersona } = action.meta.arg;

				if (state.loading === 'pending' && state.email === userEmail && state.userName === userName && state.activePersona === activePersona) {
					state.loading = 'idle'
					state.entities = action.payload
					state.email = undefined
					state.password = undefined
				}
			})
			.addCase(registerUser.rejected, (state, action) => {

				const { email, password, error } = action.meta.arg

				if (state.loading === 'pending' && state.email === email && state.password === password) {
					state.loading = 'idle'
					state.error = error
					state.entities = []
					state.email = undefined
					state.password = undefined
				}
			})
			.addCase(socialRegistration.pending,(state,action)=>{
				const {activePersona} = action.meta.arg

				if(state.loading==='idel'){
                 state.loading='pending'
                 state.activePersona = activePersona
				}
			})
			.addCase(socialRegistration.fulfilled,(state,action)=>{

				const {activePersona } = action.meta.arg;
				if(state.loading==='pending'&& state.activePersona===activePersona){
                    state.loading = 'idle'
					state.entities = action.payload
					state.email = undefined
					state.password = undefined
				}
			})
			.addCase(socialRegistration.rejected,(state,action)=>{
             const {activePersona,error} =  action.meta.org

			 if (state.loading === 'pending' && state.activePersona === activePersona) {
				state.loading = 'idle'
				state.error = error
				state.entities = []
				state.email = undefined
				state.password = undefined
			}

			})

			.addCase(loginUser.pending, (state, action) => {

				const { email, password } = action.meta.arg;

				if (state.loading === 'idle') {
					state.loading = 'pending'
					state.email = email
					state.password = password
				}
			})
			.addCase(loginUser.fulfilled, (state, action) => {

				const { email, password } = action.meta.arg;

				if (state.loading === 'pending' && state.email === email && state.password === password) {
					state.loading = 'idle'
					state.entities = action.payload
					state.email = undefined
					state.password = undefined
				}
			})
			.addCase(loginUser.rejected, (state, action) => {

				const { email, password, error } = action.meta.arg

				if (state.loading === 'pending' && state.email === email && state.password === password) {
					state.loading = 'idle'
					state.error = error
					state.entities = []
					state.email = undefined
					state.password = undefined
				}
			})
			.addCase(getUserById.pending, (state) => {
				state.loading = 'loading'
			})
			.addCase(getUserById.fulfilled, (state, { payload }) => {
				state.loading = 'loaded'
				state.currentUser = payload?.data
			})
			.addCase(getUserById.rejected, (state, action) => {
				state.loading = 'rejected'
				state.error = 'error getting property data'
				state.propertyList = {}
			})

			.addCase(getCurrentUserList.pending, (state) => {
				state.loading = 'loading'
			})
			.addCase(getCurrentUserList.fulfilled, (state, action) => {
				state.loading = 'loaded'
				state.currentUserList = action.payload?.data?.result;
			})
			.addCase(getCurrentUserList.rejected, (state, action) => {
				state.loading = 'rejected'
				state.error = 'error getting property data'
				state.propertyList = {}
			})

			.addCase(getCurrentLawyers.pending, (state) => {
				state.loading = 'loading'
			})
			.addCase(getCurrentLawyers.fulfilled, (state, action) => {
				state.loading = 'loaded'
				state.lawyerPersonaList = action.payload;
			})
			.addCase(getCurrentLawyers.rejected, (state, action) => {
				state.loading = 'rejected'
				state.error = 'error getting property data'
			})

			.addCase(getCurrentPhotographers.pending, (state) => {
				state.loading = 'loading'
			})
			.addCase(getCurrentPhotographers.fulfilled, (state, action) => {
				state.loading = 'loaded'
				state.photographerPersonaList = action.payload;
			})
			.addCase(getCurrentPhotographers.rejected, (state, action) => {
				state.loading = 'rejected'
				state.error = 'error getting property data'
			})

			.addCase(getCurrentVirtualAssistants.pending, (state) => {
				state.loading = 'loading'
			})
			.addCase(getCurrentVirtualAssistants.fulfilled, (state, action) => {
				state.loading = 'loaded'
				state.virtualAssistantPersonaList = action.payload;
			})
			.addCase(getCurrentVirtualAssistants.rejected, (state, action) => {
				state.loading = 'rejected'
				state.error = 'error getting property data'
			})
			.addCase(notifications.pending, (state) => {
				state.loading = 'loading'
				state.notifications.isLoadingNotifications=true
			})
			.addCase(notifications.fulfilled, (state, action) => {
				state.loading = 'loaded'
				state.notifications.isLoadingNotifications=false
				state.notifications.currentPage=action?.payload?.data?.currentPage;
				state.notifications.totalPages=action?.payload?.data?.totalPages;
				if(action?.payload?.data?.currentPage!==1){
					state.notifications.notificationsList = [...state.notifications.notificationsList,...action?.payload?.data?.result];
				}
				else{
					state.notifications.notificationsList = action?.payload?.data?.result
				}
			})
			.addCase(notifications.rejected, (state, action) => {
				state.notifications.isLoadingNotifications=false
				state.loading = 'rejected'
				state.error = 'error getting property data'
			})
			.addCase(getBuyerByUserId.pending, (state) => {
				state.loading = 'loading'
			})
			.addCase(getBuyerByUserId.fulfilled, (state, { payload }) => {
				state.loading = 'loaded'
				state.currentUser = payload?.data
			})
			.addCase(getBuyerByUserId.rejected, (state, action) => {
				state.loading = 'rejected'
				state.error = 'error getting property data'
				state.propertyList = {}
			})
			.addCase(getSellerByUserId.pending, (state) => {
				state.loading = 'loading'
			})
			.addCase(getSellerByUserId.fulfilled, (state, { payload }) => {
				state.loading = 'loaded'
				state.currentUser = payload?.data
			})
			.addCase(getSellerByUserId.rejected, (state, action) => {
				state.loading = 'rejected'
				state.error = 'error getting property data'
				state.propertyList = {}
			})

			.addCase(occasionalUpdateUserCountry.pending, (state) => {
				state.loading = 'loading'
			})
			.addCase(occasionalUpdateUserCountry.fulfilled, (state, { payload }) => {
				if(!payload?.noUdateNeed){

					state.loading = 'loaded'
					let updatedUser = payload?.data
					let currentAuthenticatedUser= getLocalStorageItem("authenticatedUser")
					? getLocalStorageItem("authenticatedUser")
					: null;
					let authenticatedUser={
						...currentAuthenticatedUser,
						userCountry:updatedUser.userCountry
					};
					setLocalStorageItem('authenticatedUser', authenticatedUser);					
				}

			})
			.addCase(occasionalUpdateUserCountry.rejected, (state, action) => {
				state.loading = 'rejected'
				state.error = 'error getting property data'
				state.propertyList = {}
			})

	}
})
export const {changeIsUserLocationAddressAvailable}=authSlice.actions
export default authSlice.reducer;