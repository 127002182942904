/**
 * This file will contains functions with long lines of code logics
 */

import {
  buyerRedirectNotification,
  changeCurrentSelectedOffer,
  listofsentoffers,
} from "../../../features/user/buyerSlice";
import { getPropertyDetails } from "../../../features/property/propertySlice";
import { setLocalStorageItem } from "../../../config/localStorageEncryption";
import Cookie from "js-cookie";

export const handleLogout = () => {
  localStorage.removeItem("activePersonaDetails");
  localStorage.removeItem("authenticatedUser");
  localStorage.removeItem("activePersona");
  localStorage.removeItem("user");
  localStorage.removeItem("currentTokenNotification");
  localStorage.removeItem("virtualAssistantRefLink");
  Cookie.remove('userToken');
  Cookie.remove("activerPersona")
  window.location.pathname = "/login";
};

export const handleListofSentOffers = (item, dispatch, location) => {
  let allOffers = [];
  let redirectUrl = "";

  dispatch(listofsentoffers()).then((r) => {
    if (r?.payload?.data?.listOfOffers?.length) {
      const sortedArr = r?.payload?.data?.listOfOffers?.sort(
        (a, b) => b?.propertyOfferId - a?.propertyOfferId
      );
      allOffers = sortedArr;

      if (allOffers?.length) {
        allOffers.forEach((offer) => {
          if (offer?.propertyOfferId === item?.propertyOfferId) {
            setLocalStorageItem("propertyId", offer?.propertyId);

            dispatch(getPropertyDetails()).then((r) => {
              if (r?.payload?.data?.data) {
                dispatch(
                  changeCurrentSelectedOffer({
                    currentSelectedOffer: item?.propertyOfferId,
                  })
                );

                setLocalStorageItem(
                  "buyerOfferProperty",
                  r?.payload?.data?.data
                );
                redirectUrl = "/buyer/dashboard";

                dispatch(buyerRedirectNotification(redirectUrl));

                if (location?.pathname === "/buyer/dashboard") {
                  window.location.reload();
                }
              }
            });
          }
        });
      }
    }
  });
};
