import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookie from "js-cookie";
import instance from "../../config/axiosConfig";

import { toast } from "react-toastify";
import { getUserLocationDetailByName } from "../../hooks/useTranslator";
import { getLocalStorageItem } from "../../config/localStorageEncryption";

const initialState = {
  value: 0,
  property: [],
  offer: null,
  loading: "idle",
  requestStatus: false,
  price: "",
  error: null,
  finalPrice: "",
  notificationRedirect: "",
  feePayer: "both",
  atomChatConfig: null,
  currentSelectedOffer: null,
};

export const getBuyerById = createAsyncThunk(
  "buyer/getBuyerById",
  async (_, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(`/buyer`, config);
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const buyerAddReviewTiPropertyOffer = createAsyncThunk(
  "buyer/add-review-to-propertyoffer",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.post(
        `/buyer/add-review-to-propertyoffer`,
        payload,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return error;
    }
  }
);

export const getAllContractTemplate = createAsyncThunk(
  "contract/getAllTemplates",
  async (_, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.get(`/contract/getTemplates`, config);
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const getDownloadDocument = createAsyncThunk(
  "contract/getDownloadDocument",
  async (documentId, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.open("open");
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(
        `/contract/downloadDocument?documentId=${documentId}`,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");
      return response.json();
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const buyerSignContractWithSeller = createAsyncThunk(
  "buyer/signcontractwithseller",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.post(
        `/buyer/signcontractwithseller`,
        payload,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const buyerUploadContract = createAsyncThunk(
  "buyer/contract/embeddedDocumentLink",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await instance.post(
        `/contract/embeddedDocumentLink`,
        payload?.body,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.errors?.join(" or "));
    }
  }
);

export const buyerPropertyFavourites = createAsyncThunk(
  "buyer/property-favourites",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await instance.post(
        `/property-favourites`,
        payload,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return error;
    }
  }
);

export const deleteFavoriteProperty = createAsyncThunk(
  "/delete-favorite-property",
  async (propertyId, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.delete(
        `/property-favourites/${propertyId}`,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const getAllFavorite = createAsyncThunk(
  "property-favourites/get-buyer-property-favourites",
  async (_, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.get(
        `/property-favourites/get-buyer-property-favourites`,
        config
      );
      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const getOneContractTemplate = createAsyncThunk(
  "contract/getOneTemplates",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.post(
        `/contract/embeddedTemplateLink?templateId=${payload?.templateId}`,
        payload?.info,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const embeddedSignLink = createAsyncThunk(
  "embeddedSignLink",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.post(
        `/contract/embeddedSignLink?documentId=${payload?.documentId}`,
        payload?.info,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const createStripeAccount = createAsyncThunk(
  "buyer/createStripeAccount",
  async (_, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const buyerProfileDetails = getLocalStorageItem("activePersonaDetails") 

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(
        `/buyer/${buyerProfileDetails?.buyerPersonaId}/stripe`,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");

      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const buyerProfile = createAsyncThunk(
  "buyer/buyerProfile",
  async (_, thunkAPI) => {
    document.getElementById("loading-indicator").classList.add("open");
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(`/buyer/profile`, {}, config);
      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const postreviewforseller = createAsyncThunk(
  "buyer/postpublicreviewforseller",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.post(
        `/buyer/postpublicreviewforseller`,
        payload,
        config
      );
      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return error;
    }
  }
);

export const postreviewforvirtualassistant = createAsyncThunk(
  "buyer/postreviewforvirtualassistant",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.post(
        `/buyer/postreviewforvirtualassistant`,
        payload,
        config
      );

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getBuyerUserById = createAsyncThunk(
  "user/CurrentUser",
  async (userId, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.get(
        `/buyer/profile-by-id/${userId}`,
        config
      );

      return response;
    } catch (error) {
      // toast.error(error?.response?.data?.message)
    }
  }
);

export const listBuyerProperties = createAsyncThunk(
  "buyer/listBuyerProperties",
  async (_, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(`/property`, config);
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const listofsentoffers = createAsyncThunk(
  "buyer/listofsentoffers",
  async (_, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `buyer/listofsentoffers`,
        {},
        config
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const listSoldBuyerProperties = createAsyncThunk(
  "property/sold/buyer",
  async (payload, thunkAPI ) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(`property/sold/buyer/${payload}`, config);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const sendOffer = createAsyncThunk(
  "buyer/sendOffer",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          recaptchatoken: payload?.recaptchatoken,
        },
      };
      delete payload?.recaptchatoken;
      const response = await instance.post(
        "/buyer/sendpropertyoffer",
        payload,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");

      return toast.error(error?.response?.data?.message);
    }
  }
);

export const updateOffer = createAsyncThunk(
  "buyer/updateOffer",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        "/buyer/changepropertyoffer",
        payload,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const sendRequestVirtualAssistant = createAsyncThunk(
  "buyer/sendRequestVirtualAssistant",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/buyer/sendrequestvirtualassistant`,
        payload,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getpropertyofferbyid = createAsyncThunk(
  "buyer/getpropertyofferbyid",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(
        `/buyer/getpropertyofferbyid/${payload}`,
        config
      );
      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const sendtimeframesformeeting = createAsyncThunk(
  "buyer/sendtimeframesformeeting",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        "/buyer/sendtimeframesformeeting",
        payload,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const declinesellertimeandsendnew = createAsyncThunk(
  "buyer/declinesellertimeandsendnew",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/buyer/declinesellertimeandsendnew`,
        payload,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const requestformortgage = createAsyncThunk(
  "buyer/requestformortgage",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}`,recaptchatoken:payload?.recaptchatoken },
      };
      delete payload?.recaptchatoken;
      const response = await instance.post(
        "/buyer/requestformortgage",
        payload,
        config
      );
      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const uploadpreapprovedcertificate = createAsyncThunk(
  "buyer/uploadpreapprovedcertificate",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await instance.post(
        "/buyer/uploadpreapprovedcertificate",
        payload,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const buyerCertificates = createAsyncThunk(
  "crm/buyerCertificates",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await instance.post(
        "/crm/buyerCertificates",
        payload,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const cancelOffer = createAsyncThunk(
  "buyer/cancelOffer",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        "/buyer/cancelOffer",
        payload,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const canceloffer = createAsyncThunk(
  "buyer/canceloffer",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/buyer/canceloffer`,
        payload,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const acceptingmeetingtimefromseller = createAsyncThunk(
  "buyer/acceptingmeetingtimefromseller",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/buyer/acceptingmeetingtimefromseller`,
        payload,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const selectNotaryOption = createAsyncThunk(
  "buyer/selectNotaryOption",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/buyer/selectnotaryoption`,
        payload,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const proceedwithbuyingpropertyoffer = createAsyncThunk(
  "buyer/proceedwithbuyingpropertyoffer",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/buyer/proceedwithbuyingpropertyoffer`,
        payload,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const postpublicreviewforseller = createAsyncThunk(
  "buyer/postpublicreviewforseller",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/buyer/postpublicreviewforseller`,
        payload,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const paymentBuyerDeposit = createAsyncThunk(
  "buyer/payments/buyer-deposit",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        "/payments/buyer-deposit",
        payload,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const paymentHandleAbidorsVaCommision = createAsyncThunk(
  "buyer/payments/handle-abidors-va-commission",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        "/payments/handle-abidors-va-commission",
        payload,
        config
      );
      document.getElementById("loading-indicator").classList.remove("open");

      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");

      return toast.error(error?.response?.data?.message);
    }
  }
);

export const confirmAbidorsVaCommission = createAsyncThunk(
  "buyer/confirm-abidors-va-commission",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        "/payments/confirm-abidors-va-commission",
        payload,
        config
      );
      document.getElementById("loading-indicator").classList.remove("open");

      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");

      return toast.error(error?.response?.data?.message);
    }
  }
);

export const confirmBuyerDeposit = createAsyncThunk(
  "buyer/payments/confirm-buyer-deposit",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        "/payments/confirm-buyer-deposit",
        payload,
        config
      );
      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const updateProfilePic = createAsyncThunk(
  "user/updateProfilePic",
  async (picture, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const personaId = getLocalStorageItem("activePersonaDetails")?.buyerPersonaId;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await instance.put(
        `/buyer/${personaId}/images`,
        picture,
        config
      );

      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteProfilePic = createAsyncThunk(
  "seller/deleteProfilePic",
  async (_, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const personaId = getLocalStorageItem("activePersonaDetails")?.buyerPersonaId;

      const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { fileType: "profilePicture" },
      };

      const response = await instance.delete(
        `/buyer/${personaId}/images`,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const getBuyerProfile = createAsyncThunk(
  "/buyer/getbuyerprofile",
  async (userId, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.get(
        `/buyer/profile-by-id/${userId}`,
        config
      );
      return response;
    } catch (error) {
      // toast.error(error?.response?.data?.message)
    }
  }
);

export const getAtomChatConfig = createAsyncThunk(
  "/chats/user-status/get-atomchat-config",
  async () => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.get(
        `/chats/user-status/get-atomchat-config`,
        config
      );
      return response;
    } catch (error) {
      // toast.error(error?.response?.data?.message)
    }
  }
);

export const getMortgageApplicationBanksByBuyerCountry = createAsyncThunk(
  "buyer/getMortgageApplicationBanksByBuyerCountry",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");
      const token = Cookie.get("userToken");
      let country=await getUserLocationDetailByName("countryName")
      const data={
        country
      }
      const config = {
        params:data,
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(
        "/buyer/get-mortage-application-banks-by-country",
        // data,
        config
      );
      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

const buyerSlice = createSlice({
  name: "buyer",
  initialState,
  reducers: {
    buyerPrice: (state, action) => {
      return {
        ...state,
        price: action?.payload,
      };
    },
    buyerFinalPrice: (state, action) => {
      return {
        ...state,
        finalPrice: action?.payload,
      };
    },
    buyerFeePayer: (state, action) => {
      return {
        ...state,
        feePayer: action?.payload,
      };
    },
    buyerRedirectNotification: (state, action) => {
      return {
        ...state,
        notificationRedirect: action?.payload,
      };
    },
    changeCurrentSelectedOffer: (state, action) => {
      return {
        ...state,
        currentSelectedOffer: action?.payload?.currentSelectedOffer,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBuyerById.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(getBuyerById.fulfilled, (state) => {
      state.loading = "loaded";
    });
    builder.addCase(getBuyerById.rejected, (state) => {
      state.loading = "rejected";
    });
    builder
      .addCase(listBuyerProperties.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(listBuyerProperties.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        state.property = payload;
      })
      .addCase(listBuyerProperties.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
      })
      .addCase(sendOffer.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(sendOffer.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        state.offer = payload;
      })
      .addCase(sendOffer.rejected, (state, action) => {
        state.loading = "rejected";
        state.offer = action.meta;
        state.error = "sending offer failed!";
      })
      .addCase(sendRequestVirtualAssistant.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(sendRequestVirtualAssistant.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        state.requestStatus = true;
      })
      .addCase(sendRequestVirtualAssistant.rejected, (state, action) => {
        state.loading = "rejected";
      })

      .addCase(getpropertyofferbyid.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getpropertyofferbyid.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        state.offer = payload?.data;
      })
      .addCase(getpropertyofferbyid.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
      })
      .addCase(getAtomChatConfig.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getAtomChatConfig.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        state.atomChatConfig = payload?.data;
      })
      .addCase(getAtomChatConfig.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
      });
  },
});

export const {
  buyerPrice,
  buyerFinalPrice,
  buyerFeePayer,
  buyerRedirectNotification,
  changeCurrentSelectedOffer,
} = buyerSlice.actions;
export default buyerSlice.reducer;
