import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/user/authSlice";
import sellerSlice from "./features/user/sellerSlice";
import buyerSlice from "./features/user/buyerSlice";
import messageSlice from "./features/messages/messageSlice";
import propertySlice from "./features/property/propertySlice";
import lawyerSlice from "./features/user/lawyerSlice";
import photographerSlice from "./features/user/photographerSlice";
import virtualAssistantSlice from "./features/user/virtualAssistantSlice";
import contractSlice from "./features/common/contractSlice";
import multiprofileSlice from "./features/common/mutiprofile.Slice";
import reviewSlice from "./features/common/reviewSlice";
import serviceSlice from "./features/common/serviceSlice";
import serviceRequestSlice from "./features/common/serviceRequestSlice";
import statisticsSlice from "./features/common/statisticsSlice";
import logger from "redux-logger";
import emailsSubscriptionSlice from "./features/common/emailsSubscriptionSlice";
import sellerActionSlice from "./features/common/sellerActionSlice";
import serviceRequestVirtualToursSlice from './features/common/serviceRequestVirtualToursSlice'
import progressSlice from "./features/common/progressSlice";
import virtualTourSlice from './features/virtual_tour/virtualTourSlice';
import recaptchaSlice from './features/googlerecaptcha/recaptchaSlice';
import personaTransactionsSlice from "./features/common/personaTransactionsSlice"
import propertyViewSlice from "./features/common/propertyViewSlice";
const store = configureStore({
  middleware:process.env.NODE_ENV==="development" && process.env.REACT_APP_NO_LOGGER !== 'true' ?(getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }).concat(logger): (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    authenticatedUser: authReducer,
    propertySlice: propertySlice,
    sellerSlice: sellerSlice,
    buyerSlice: buyerSlice,
    messageSlice: messageSlice,
    lawyerSlice: lawyerSlice,
    photographerSlice: photographerSlice,
    virtualAssistantSlice: virtualAssistantSlice,
    contractSlice: contractSlice,
    multiprofileSlice: multiprofileSlice,
    emailsSubscriptionSlice: emailsSubscriptionSlice,
    reviewSlice: reviewSlice,
    serviceSlice: serviceSlice,
    serviceRequestSlice: serviceRequestSlice,
    sellerAction:sellerActionSlice,
    statisticsSlice: statisticsSlice,
    serviceRequestVirtualToursSlice: serviceRequestVirtualToursSlice,
    progressSlice:progressSlice,
    virtualTourSlice: virtualTourSlice,
    recaptchaSlice:recaptchaSlice,
    personaTransactionsSlice:personaTransactionsSlice,
    propertyViewSlice:propertyViewSlice
  },
  devTools: process.env.NODE_ENV !== "production" ? true : false,
});

export default store;
