export const allSupportedLanguages = [
    {
        code: "bg",
        name: "Bulgare",
        nativeName: "Български",
        codeForFlag: 'bg'
    },
    {
        "code": "en",
        "name": "Anglais",
        "nativeName": "English",
        codeForFlag: 'us'
    },
    {
        "code": "es",
        "name": "Espagnol",
        "nativeName": "Español",
        codeForFlag: 'es'
    },
    {
        code: "fr",
        name: "Français",
        nativeName: "Français",
        codeForFlag: 'fr'
    },
    {
        code: "fr-CA",
        name: "Français (Canada)",
        nativeName: "Français (Canada)",
        codeForFlag: 'ca'
    },
    {
        code: "it",
        name: "Italien",
        nativeName: "Italiano",
        codeForFlag: 'it'
    },
    {
        code: "ru",
        name: "Russe",
        nativeName: "Русский",
        codeForFlag: 'ru'
    },
    {
        code: "tr",
        name: "Turc",
        nativeName: "Türkçe",
        codeForFlag: 'tr'
    },
    {
        code: "fa",
        name: "Persan",
        nativeName: "فارسی",
        codeForFlag: 'ir'
    },
    {
        code: "de",
        name: "Allemand",
        nativeName: "Deutsch",
        codeForFlag: 'de'
    },
    {
        code: "ja",
        name: "Japonais",
        nativeName: "日本語",
        codeForFlag: 'jp'
    },
    {
        code: "lzh",
        name: "Chinese (Literary)",
        nativeName: "中文 (文言文)",
        codeForFlag: 'cn'
    },
    {
        code: "pt-PT",
        name: "Portugais (Portugal)",
        nativeName: "Português (Portugal)",
        codeForFlag: 'pt'
    },
    {
        code: "el",
        name: "Grec",
        nativeName: "Ελληνικά",
        codeForFlag: 'gr'
    },
    {
        code: "ar",
        name: "Arabe",
        nativeName: "العربية",
        codeForFlag: 'sa'
    },
];
