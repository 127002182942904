import { Suspense } from "react";
import "./LazyWrapper.css"; // Import your CSS file
import Images from "../../assets/uploaded_images.json"
const AbidorsPin =Images["abidorsPin.png"]
const LazyWrapper = ({ children }) => {
  return (
    <Suspense
      fallback={
        <div className="loading-container">
          <img
            src={AbidorsPin}
            alt="loading..."
            className="loading-image"
          />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

export default LazyWrapper;
