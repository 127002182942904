import Cookie from 'js-cookie'
import instance from '../config/axiosConfig'

export const getToken = () => Cookie.get('userToken')

export const shallowCopy = (obj) => {
    return Object.assign({}, obj)
}

// map state methods
export const checkProfile = ({ sellerSlice, photographerSlice, serviceRequestVirtualToursSlice }) => ({
    isSeller: sellerSlice?.loading === 'loaded',
    isPhotographer: photographerSlice?.loading === 'loaded',
    isServiceProfileRequest: !!serviceRequestVirtualToursSlice.selectedVirtualTourId
})
export const getSelectedVirtualTourId = ({ sellerSlice, photographerSlice, serviceRequestVirtualToursSlice }) => {
    const profileData = checkProfile({ sellerSlice, photographerSlice, serviceRequestVirtualToursSlice })
    return {
        selectedVirtualTourId: chooseByProfile(
            profileData,
            serviceRequestVirtualToursSlice.selectedVirtualTourId,
            photographerSlice?.selectedVirtualTourId,
            sellerSlice?.virtualTours[0]?.virtualTourId ??sellerSlice?.virtualTours[0]?.panoramas[0]?.virtualTourId,
        ),
    }
}

export const isStateLoading = ({ sellerSlice, photographerSlice, serviceRequestVirtualToursSlice }) => {
    const profileData = checkProfile({ sellerSlice, photographerSlice, serviceRequestVirtualToursSlice })
    return {
        isLoading: chooseByProfile(
            profileData,
            serviceRequestVirtualToursSlice.isLoading,
            photographerSlice?.isLoading,
            sellerSlice?.isLoading
        ),
    }
}

export const mapStateChain = (...mapstates) => {
    return (state) => {
        let res = {}
        for (let mapstate of mapstates) res = { ...res, ...mapstate(state) }
        return res
    }
}

export const chooseByProfile = (profileData, serviceRequest, photographer, seller) => {
    // the priority is as follows:
    // 1. isServiceProfileRequest
    // 2. isPhotographer
    // 3. isSeller
    const { isServiceProfileRequest, isPhotographer } = profileData
    if (isServiceProfileRequest) return serviceRequest
    if (isPhotographer) return photographer
    return seller
}

const DELETE_URLS = {
    panorama: `virtual-tours/panoramas/`,
    virtualTour: `virtual-tours/`,
    infospot: `virtual-tours/panoramas/infospots/`
}

export const updateVirtualTourApiThunkFn = async (payload, thunkAPI) => {
    try {
        let {
            body,
            config = {},
            virtualTourId,
            panoramaId,
            isUpdatePanorama,
            isAddingPanorama,
            isDeleting,
            isInfospot,
            isSorting,
            isFloorplan,
            isTourName,
            id
        } = payload

        if (!virtualTourId && !isUpdatePanorama && !isDeleting && !isInfospot && !isSorting) 
        throw new Error(`virtualTourId must be provided`)
        if ((isUpdatePanorama || isSorting) && !panoramaId ) 
        throw new Error(`panoramaId must be provided`)
        if (isDeleting && !Object.keys(DELETE_URLS).includes(isDeleting)) throw new Error(`invalid isDeleting parameter (${isDeleting})`)

        const token = getToken()
        config = {
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${token}`,
                'Content-Type': (isAddingPanorama || isFloorplan || isTourName) ? 'multipart/form-data' : 'application/json'
            }
        }
        let METHOD = isDeleting ? 'delete' : 'put'
        let URL = isUpdatePanorama ? `virtual-tours/panoramas/${panoramaId}` : `virtual-tours/${virtualTourId}`
        if (isAddingPanorama) URL = `virtual-tours/panoramas`
        if(isDeleting) URL = DELETE_URLS[isDeleting]
        if(isInfospot) URL = `virtual-tours/panoramas/${panoramaId}/infospots`
        if(isSorting) URL = `virtual-tours/panoramas/change-position-of-panorama`

        let response;
        if (METHOD === 'delete') {
            response = await instance[METHOD](URL + id, config)
        } else {
            if(isAddingPanorama || isInfospot || isSorting) METHOD = 'post'
            response = await instance[METHOD](URL, body, config)
        }
        return response
    } catch ({ message: error }) {
        return thunkAPI.rejectWithValue({ error })
    }
}

export const isLink = (str) => new RegExp(/http(?:s)?:\/\/.+/).test(str)

export function maskEmail(email) {
    if (!email) return '';
    const [username, domain] = email.split('@');

    const usernameLength = username.length;

    const maskedUsername = username[0] + '*'.repeat(usernameLength -  2) + username[usernameLength -  1];

    return maskedUsername + '@' + domain;
}

export function jsonToQueryString(json) {
    if (typeof json !== 'object' || json === null) {
        return '';
    }

    if (Object.keys(json).length === 0) {
        return '';
    }

    function handleNested(key, value) {
        if (typeof value === 'object' && value !== null) {
            return Object.keys(value).map(subKey => `${encodeURIComponent(key)}[${encodeURIComponent(subKey)}]=${encodeURIComponent(value[subKey])}`).join('&');
        } else {
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
    }

    return Object.keys(json)
        .map(key => handleNested(key, json[key]))
        .join('&');
}

export function divideInto(array, subarraySize, insert) {
    let subarrays = [];
    for (let i = 0; i < array.length; i += subarraySize) {
        let subarray = array.slice(i, i + subarraySize);
        subarrays.push(subarray);
    }
    return (insert === undefined) ? subarrays : insertValueAfterEveryOther(subarrays, insert);
}

function insertValueAfterEveryOther(array, value) {
    let newArray = [];
    let newArrSize = array.length * 2 - 1;
    for (let i = 0; i < newArrSize; i++) {
        if (i % 2 === 0) {
            newArray.push(array[i / 2]);
        } else {
            newArray.push(value);
        }
    }
    return newArray;
}

export function replaceInString(str, obj) {
    let result = String(str);
    for (let key in obj) {
      result = result.replace(new RegExp(`\\$${key}`, "g"), obj[key]);
    }
    return result;
  }
