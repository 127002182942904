/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./HeaderSeller.css";
import { Button } from "@material-ui/core";
/* icons */
import { FaSearch } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
/* svgs */

import Images from "../../../assets/uploaded_images.json";

import PlacesAutocomplete from "react-places-autocomplete";
import {
  filtersProperty,
  geocodeAddressLocation,
} from "../../../features/property/propertySlice";
import { contractDetails } from "../../../features/common/contractSlice";
import {
  notifications,
  notificationsPropertyStatusCheck,
  readNotifications,
} from "../../../features/user/authSlice";
import SideBarMenu from "./headerSideBar";
import { LangSwitcher } from "../../common/LangSwitcher/LangSwitcher";
import { useMediaQuery } from "react-responsive";
import {
  resetInformationTab,
  resetProperty360Walk,
  resetVirtualTour,
  sellerRedirectNotification,
} from "../../../features/user/sellerSlice";
import { useTranslation } from "react-i18next";
import Notifications from "./../../common/Notifications/Notification";
import {
  listServiceRequests,
  resetServiceRequest,
} from "../../../features/common/serviceRequestSlice";
import { dispatchPropertyOffers, handleLogout } from "./functions";
import { validateInput } from "./../../../helpers/userInputEscaper";
import { toast } from "react-toastify";
import OverLaySearchBox from "./../../common/search/Dialog/Dialog";
import { getUserLang } from "../../../hooks/useTranslator";
import { supportedLanguages } from "../../common/search/SupportedLanguages";
import { getLocalStorageItem, setLocalStorageItem } from "../../../config/localStorageEncryption";
import ProperyCardIsStillActive from "../../common/PropertyCard/ProperyCardIsStillActive";

const { useStyles } = require("./styles");
const Account = Images["account-header.svg"];
const HomeOutline = Images["HomeOutline.svg"];
const Logo = Images["Logo.svg"];
const AddIconProperty = Images["add-property-icon.svg"];

const HeaderSeller = () => {
  const isTabletDevice = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const colorValue = useMemo(() => ({ color: "#fff" }), []);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const userLanguage = getUserLang();
  const [isLangSupported, setIsLangSupported] = useState(false);
  const activeUser = getLocalStorageItem("user")? getLocalStorageItem("user") : {};

  const storeAddress = useSelector(
    (state) => state.propertySlice?.filters?.address
  );

  const personaId = getLocalStorageItem("activePersonaDetails")?.sellerPersonaId;
  const messageRef = useRef(null);

  const [address, setAddress] = useState("");
  const [openNotificaration, setOpenNotification] = useState(false);

  useEffect(() => {
    let addr = "";

    const locPathCondition = location?.pathname === "/search-properties";

    if (address !== storeAddress && locPathCondition) {
      addr = storeAddress;
    }

    if (address !== storeAddress) {
      setAddress(addr);
    }
  }, [storeAddress]);

  useEffect(() => {
    dispatch(sellerRedirectNotification(""));

    const checkIfClickedOutside = (e) => {
      if (
        openNotificaration &&
        messageRef.current &&
        !messageRef.current.contains(e.target)
      ) {
        setOpenNotification(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [openNotificaration]);


  const handleClick = () => {
    navigate("/search-properties");
  };

  const handleSelect = async (value) => {
    let resultAddress = await geocodeAddressLocation(value);
    if (
      (!resultAddress?.formatted_address &&
        !resultAddress?.latitude &&
        !resultAddress?.longitude &&
        !resultAddress?.municipality &&
        !resultAddress?.region) ||
      !resultAddress?.continent
    ) {
      toast.error("Please choose another suitable and accurate address");
    } else {
      let newSearchQuery = {
        address: value,
      };
      if (resultAddress?.municipality) {
        newSearchQuery.municipality = resultAddress?.municipality;
      }
      if (resultAddress?.region) {
        newSearchQuery.region = resultAddress?.region;
      }
      if (resultAddress?.continent) {
        newSearchQuery.continent = resultAddress?.continent;
      }
      if (resultAddress?.country) {
        newSearchQuery.country = resultAddress?.country;
      }

      dispatch(filtersProperty(newSearchQuery));

      setAddress("");

      navigate("/search-properties");
    }
  };

  /* Handle redirect to create property */
  const handleCreateProperty = () => {
    if (activeUser?.isRestricted) {
      dispatch(contractDetails({
          activePersona: "seller",
          isOpenModal: true,
        }));

      return toast.warning(`${t("Твоят акаунт е в процес на верифициране. Очаквай отговор скоро!")}`);
    }

    dispatch(resetInformationTab());
    dispatch(resetProperty360Walk());
    dispatch(resetVirtualTour());
    navigate("/seller/post-an-add/information");
  };

  const handleClickNotification = (item) => {
    const activePersonaDetailsUserId = getLocalStorageItem("activePersonaDetails")
      ? getLocalStorageItem("activePersonaDetails")?.userId
      : "";

    let redirectUrl = "";

    if (item?.notificationType === "propertyApproved") {
      redirectUrl = `property-details?${item?.propertyId}`;
    } else if (item?.notificationType === "approvedPayCommission") {
      redirectUrl = `property-details?${item?.propertyId}`;
    } else if (item?.notificationType === "buyerPublicReview") {
      redirectUrl = `/seller/public-profile?userId=${activePersonaDetailsUserId}`;
    } else if (
      item?.notificationType === "receivedOffer" &&
      (item?.propertyId || item?.propertyOfferId)
    ) {
      setLocalStorageItem("chosenPropertyNotif", item?.propertyId);
      redirectUrl = `/seller/my-adds`;
    } else if (item?.notificationType === "buyerRequest") {
      dispatchPropertyOffers(item, dispatch, location);
    } else if (item?.notificationType === "buyerChangeDateViewing") {
      dispatchPropertyOffers(item, dispatch, location);
    } else if (item?.notificationType === "buyerAcceptViewingRequest") {
      dispatchPropertyOffers(item, dispatch, location);
    } else if (item?.notificationType === "buyerCompleteOffer") {
      dispatchPropertyOffers(item, dispatch, location);
    } else if (item?.notificationType === "buyerSeizesProperty") {
      dispatchPropertyOffers(item, dispatch, location);
    } else if (item?.notificationType === "buyerSignContract") {
      dispatchPropertyOffers(item, dispatch, location);
    } else if (item?.notificationType === "contractSigneBuyerSeller") {
      dispatchPropertyOffers(item, dispatch, location);
    } else if (item?.notificationType === "lawyerAcceptedInquiry") {
      dispatch(
        listServiceRequests({ customerId: personaId, customerType: "seller" })
      );
      dispatch(resetServiceRequest());
      redirectUrl = "/seller/profile?" + item?.senderPersona + "s";
    } else if (
      item?.notificationType === "photographerMarkvirtualTourCompleted"
    ) {
      dispatch(
        listServiceRequests({ customerId: personaId, customerType: "seller" })
      );
      dispatch(resetServiceRequest());
      redirectUrl = "/seller/profile?" + item?.senderPersona + "s";
    } else if (
      item?.notificationType === "lawyerAcceptedPayment" &&
      !item?.propertyId &&
      !item?.propertyOfferId
    ) {
      dispatch(
        listServiceRequests({ customerId: personaId, customerType: "seller" })
      );
      dispatch(resetServiceRequest());
      redirectUrl = "/seller/profile?" + item?.senderPersona + "s";
    } else if (item?.notificationType === "lawyerMarkPerformed") {
      dispatch(
        listServiceRequests({ customerId: personaId, customerType: "seller" })
      );
      dispatch(resetServiceRequest());
      redirectUrl = "/seller/profile?" + item?.senderPersona + "s";
    } else if (
      item?.notificationType === "vaRejectedJobRequest" ||
      item?.notificationType === "photograprapherCancelledJobRequest" ||
      item?.notificationType === "lawyerCancelledJobRequest" ||
      item?.notificationType === "photograprapherRejectedJobRequest" ||
      item?.notificationType === "vaRejectedJobRequest" ||
      item?.notificationType === "lawyerRejectedJobRequest"
    ) {
      if (item?.senderPersona === "lawyer") {
        redirectUrl =
          "/third-party/lawyer-profile/" + item?.senderActivePersonaId;
      }
      if (item?.senderPersona === "photographer") {
        redirectUrl =
          "/third-party/photographer-profile/" + item?.senderActivePersonaId;
      }
      if (item?.senderPersona === "virtualassistant") {
        redirectUrl =
          "/third-party/virtual-assistant-profile/" +
          item?.senderActivePersonaId;
      }
    }
    dispatch(sellerRedirectNotification(redirectUrl));

    dispatch(readNotifications(item?.notificationId)).then((r) => {
      if (r?.payload?.data?.status) {
        setOpenNotification(false);
        dispatch(notifications());
      }
    });
  };

  useEffect(() => {
    const supportedLanguage = supportedLanguages.find(
      (lang) => lang.code === userLanguage
    );
    if (supportedLanguage) {
      setIsLangSupported(true);
    }
  }, [userLanguage]);

  const findStatusCheckNotification = () => {
    dispatch(notificationsPropertyStatusCheck({
      all: true,
      filters: {
        notificationType: 'propertyStatusCheck',
        userId: activeUser?.userId,
        read: 0
      }
    })).then((r) => {
      console.log({r})
      const fetchedData = r?.payload?.data?.result;

      const uniquePropertyIds = new Map()
      const data = fetchedData.filter(item => {
        if (!uniquePropertyIds.has(item.property.propertyId)) {
          uniquePropertyIds.set(item.property.propertyId, true);
          return true;
        }
        return false;
      });
      
      if (data && data.length > 0) {
        data.forEach((item) => {
          toast(
            <ProperyCardIsStillActive property={item.property}  />,
            {
              autoClose: false,
              className: "is-property-active",
              toastId: `property-status-check-${item.notificationId}`,
            }
          )

        })
      }
    })
  }

  useEffect(() => {
    findStatusCheckNotification()
  }, [location])

  return (
    <header className="header-seller">
      <IconContext.Provider value={colorValue}>
        <nav className="navbar">
          <div className="clearfix" style={{ width: "100%" }}>
            <Link to="/seller" className="navbar-logo navbar-logo-seller">
              <img loading="lazy" src={Logo} alt="" />
            </Link>

            <ul className="nav-menu">
              <li className="nav-item-lang-switcher">
                <LangSwitcher />
              </li>

              <li className="nav-item">
                <Link to="/seller" className="nav-links">
                  {t("Начало")}{" "}
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/third-party?lawyers" className="nav-links">
                  {t("Адвокати")}
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/third-party?virtualAssistants" className="nav-links">
                  {t("Виртуални асистенти")}
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/third-party?photographers" className="nav-links">
                  {t("Фотографи")}
                </Link>
              </li>
              {isLangSupported && (
                <li className="nav-item">
                  <OverLaySearchBox />
                </li>
              )}
            </ul>

            {!isLangSupported && (
              <form className="search">
                <PlacesAutocomplete
                  value={validateInput(address)}
                  onChange={setAddress}
                  onSelect={handleSelect}
                  className="search"
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <FaSearch
                        className={`${classes.faSearch} search-icon`}
                        style={{ color: "#0097A7" }}
                        onClick={handleClick}
                      />
                      <input
                        className="search-field"
                        {...getInputProps({
                          placeholder: `${t("Търси имоти")}`,
                        })}
                        style={{
                          padding: "20px",
                          fontSize: "16px",
                          color: "#000",
                          width: "100%",
                          border: "none",
                          borderRadius: "8px",
                        }}
                      />

                      <div
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        }}
                      >
                        {loading ? <div>...loading</div> : null}

                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#00BCD4"
                              : "#ffffff",
                            fontSize: "18px",
                            color: suggestion.active ? "#ffffff" : "#00BCD4",
                            padding: "5px",
                            cursor: "pointer",
                          };

                          return (
                            <div
                              key={suggestion.placeId}
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </form>
            )}

            <div className="right-part">
              {isTabletDevice && (
                <div className="language-option-tablet">
                  <LangSwitcher />
                </div>
              )}

              <div
                className="add-property clearfix add-property-desktop"
                style={{ cursor: "pointer" }}
                onClick={handleCreateProperty}
              >
                <img loading="lazy" src={AddIconProperty} alt="" />

                <p className="add-text">{t("Добави имот")}</p>
              </div>

              <div className="account-group">
                <Link to="/seller/my-adds" className="clearfix link">
                  <img loading="lazy" src={HomeOutline} alt="" />

                  <p className="title">{t("моите обяви")}</p>
                </Link>
              </div>
              <div className="notification-icon">
              <Notifications
                handleNotification={handleClickNotification}
                setOpenNotification={setOpenNotification}
                openNotificaration={openNotificaration}
                messageRef={messageRef}
              />
              </div>

              <div className="bell user-account">
                <Link to="/seller/profile">
                  <img loading="lazy" src={Account} className="icon" alt="" />
                </Link>
              </div>

              <Button className="logout-button" onClick={handleLogout}>
                {" "}
                {t("Изход")}{" "}
              </Button>
              <div className="sidebar-menu-icon">
              <SideBarMenu
                AddIconProperty={AddIconProperty}
                handleCreateProperty={handleCreateProperty}
              />
              </div>
            </div>
          </div>
        </nav>
      </IconContext.Provider>
    </header>
  );
};

export default HeaderSeller;
