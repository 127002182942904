import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { FaSearch } from "react-icons/fa";
import Grow from "@material-ui/core/Grow";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete from "react-places-autocomplete";
import { validateInput } from "./../../../../helpers/userInputEscaper";
import { useDispatch } from "react-redux";
import {
  filtersProperty,
  geocodeAddressLocation,
} from "../../../../features/property/propertySlice";
import "./styles.css";
const { useStyles } = require("./styles");

const SearchDialog = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [contentHeight, setContentHeight] = useState(35);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [open, setOpen] = useState(false);
  const contentRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = async (value) => {
    let resultAddress = await geocodeAddressLocation(value);
    if (
      (!resultAddress?.formatted_address &&
        !resultAddress?.latitude &&
        !resultAddress?.longitude &&
        !resultAddress?.municipality &&
        !resultAddress?.region) ||
      !resultAddress?.continent
    ) {
      toast.error("Please choose another suitable and accurate address");
    } else {
      let newSearchQuery = {
        address: value,
      };
      if (resultAddress?.municipality) {
        newSearchQuery.municipality = resultAddress?.municipality;
      }
      if (resultAddress?.region) {
        newSearchQuery.region = resultAddress?.region;
      }
      if (resultAddress?.continent) {
        newSearchQuery.continent = resultAddress?.continent;
      }
      if (resultAddress?.country) {
        newSearchQuery.country = resultAddress?.country;
      }

      dispatch(filtersProperty(newSearchQuery));

      setAddress("");

      navigate("/search-properties");
      setOpen(false);
    }
  };

  const handleClick = () => {
    navigate("/search-properties");
  };

  useEffect(() => {
    if (contentRef.current) {
      if (contentRef.current.clientHeight === 0) {
        setContentHeight(35);
      } else if (contentRef.current.clientHeight > 0) {
        setContentHeight(contentRef.current.clientHeight);
      }
    }
  }, [address, contentRef]);

  return (
    <div style={{ display: "inline-block", marginTop: "2px" }}>
      <FaSearch
        style={{
          color: "rgb(0, 151, 167)",
          cursor: "pointer",
          marginLeft: "20px",
        }}
        onClick={handleClickOpen}
      />
      <Dialog
        classes={{
          paper: classes.dialog,
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={GrowTransition}
      >
        <DialogContent
          className={classes.content}
          style={{ height: contentHeight, overflowY: "auto" }}
        >
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              background: "red",
            }}
          >
            <form className="overlay_search">
              <div
                ref={contentRef}
                className="search-places-auto-complete-container common-search-field-container"
              >
                <PlacesAutocomplete
                  value={validateInput(address) || ""}
                  onChange={setAddress}
                  onSelect={handleSelect}
                  classNames={{
                    dropdownContainer: "search-autocomplete-dropdown",
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <FaSearch
                        className={classes.faSearch}
                        style={{ color: "#0097A7" }}
                        onClick={handleClick}
                      />
                      <input
                        className="overlay_search-field"
                        {...getInputProps({
                          placeholder: `${t("Търси имоти")}`,
                        })}
                        style={{
                          padding: "20px",
                          fontSize: "16px",
                          color: "#000",
                          width: "100%",
                          border: "none",
                          borderRadius: "8px",
                        }}
                      />
                      <div
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        }}
                        className="search-autocomplete-dropdown"
                      >
                        {loading ? <div>...loading</div> : null}

                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#00BCD4"
                              : "#ffffff",
                            fontSize: "18px",
                            color: suggestion.active ? "#ffffff" : "#00BCD4",
                            padding: "5px",
                            cursor: "pointer",
                          };

                          return (
                            <div
                              key={suggestion.placeId}
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

function GrowTransition(props) {
  return <Grow {...props} />;
}

export default SearchDialog;
