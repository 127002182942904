import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookie from "js-cookie";
import instance from "../../config/axiosConfig";

import { toast } from "react-toastify";
import { shallowCopy, updateVirtualTourApiThunkFn } from "../../utils";
import { areaUnitsData } from "../../helpers/helpers";
import { getLocalStorageItem } from "../../config/localStorageEncryption";

const defaultPropertiesInInformationTab = {
  location: {},
  propertyType: "",
  constructionStage: "",
  constructionType: "",
  constructionNew: false,
  garage: false,
  equipment: "",
  exposition: "",
  vision: "",
  yearBuilt: null,
  areaWithoutCommonParts: "",
  commonPartsIncluded: false,
  areaWithCommonParts: "",
  choosenAreaUnitType:areaUnitsData[0],
  floor: "",
  totalFloors: "",
  elevator: false,
  bedrooms: "",
  bathrooms: "",
  terraces: "",
  otherPremises: [],
  heating: [],
  floorType: [],
  closeTo: [],
  propertyDescription: "",
  propertyPros: "",
  propertyCons: "",
  countStep: 0,
  atomChatConfig: null,
};

const propertyPhotos = {
  images: [],
  video: "",
  threeSixty: "",
};

const defaultPropertiesInDocumentsTab = {
  isOwner: false,
  documentPhoto: "",
};

let initialState = {
  value: 0,
  propertyList: [],
  loading: "idle",
  offerStatus: "pending",
  requestStatus: false,
  offer: {},
  error: null,
  information: defaultPropertiesInInformationTab,
  visualPropertyTab: propertyPhotos,
  price: "",
  propertyPlan: "",
  documents: defaultPropertiesInDocumentsTab,
  finalPrice: "",
  feePayer: "both",
  notificationRedirect: "",
  virtualTour: {
    panoramas: [],
    floorPlan: {},
    showVirtualTour: false,
  },
  showVirtualTour: false,
  propertyPaginationDetails: {},
  virtualTours: [
    {
      panoramas: [],
      floorPlan: {},
    },
  ],
  selectedVirtualTourId: null,
  isLoadingData: false,
};

const getToken = () => Cookie.get("userToken");

export const listSellerProperties = createAsyncThunk(
  "seller/listSellerProperties",
  async (data, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const sellerId = getLocalStorageItem("activePersonaDetails")?.sellerPersonaId;

      let properPage = 1;

      if (data?.page) {
        properPage = data?.page;
      }

      const response = await instance.get(
        `/property/sellerProperties?sellerId=${sellerId}&page=${properPage}&size=10`,
        config
      );
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const listSoldSellerProperties = createAsyncThunk(
  "property/sold/seller",
  async (payload, thunkAPI ) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      
      const response = await instance.get(`property/sold/seller/${payload}`, config);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const sellerAddReviewTiPropertyOffer = createAsyncThunk(
  "seller/add-review-to-propertyoffer",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.post(
        `/seller/add-review-to-propertyoffer`,
        payload,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return error;
    }
  }
);

export const createStripeAccount = createAsyncThunk(
  "seller/createStripeAccount",
  async (_, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const sellerId = getLocalStorageItem("activePersonaDetails")?.sellerPersonaId;

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(`/seller/${sellerId}/stripe`, config);

      document.getElementById("loading-indicator").classList.remove("open");

      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);
export const postreviewforabidors = createAsyncThunk(
  "seller/postreviewforabidors",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.post(
        `/seller/postreviewforabidors`,
        payload,
        config
      );

      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const listSellerAllProperties = createAsyncThunk(
  "seller/listSellerAllProperties",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.get(
        `/property/sellerProperties?sellerId=${payload}&isListed=approved`,
        config
      );
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getSellerActivePropertiesCount = createAsyncThunk(
  "seller/getSellerActivePropertiesCount",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.get(
        `/seller/get-seller-active-properties-count`,
        config
      );
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const acceptOffer = createAsyncThunk(
  "seller/acceptOffer",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");
      const token = getToken();

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const payloadObj = { propertyOfferId: payload };
      const response = await instance.post(
        `/seller/acceptpropertyoffer`,
        payloadObj,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const refuseOffer = createAsyncThunk(
  "seller/refuseOffer",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const data = {
        propertyOfferId: payload,
      };
      const response = await instance.post(
        `/seller/refusepropertyoffer`,
        data,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const cancelOffer = createAsyncThunk(
  "seller/cancelOffer",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const data = {
        propertyOfferId: payload,
      };
      const response = await instance.post(`/seller/canceloffer`, data, config);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const sellerSignContractWithAbidors = createAsyncThunk(
  "seller/signAgreement",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await instance.post(
        `/seller/signAgreement`,
        payload?.body,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createProperty = createAsyncThunk(
  "seller/createProperty",
  async (payload, thunkAPI) => {
    document.getElementById("loading-indicator").classList.add("open");

    try {
      const token = getToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          recaptchatoken:payload?.recaptchatoken
        },
      };
      const response = await instance.post(`/property`, payload?.body, config);
      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.errors[0]);
      document.getElementById("loading-indicator").classList.remove("open");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateProperty = createAsyncThunk(
  "seller/updateProperty",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const propertyId = getLocalStorageItem("propertyId");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await instance.put(
        `property/${propertyId}`,
        payload?.body,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const sendRequestVirtualAssistant = createAsyncThunk(
  "seller/sendRequestVirtualAssistant",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/seller/sendrequestvirtualassistant`,
        payload,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const sendResponseToBuyerOnOffer = createAsyncThunk(
  "seller/sendResponseToBuyerOnOffer",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/seller/send-response-to-buyer-on-offer`,
        payload,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const requestNewContract = createAsyncThunk(
  "seller/requestNewContract",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/seller/requestNewContract`,
        payload,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");

      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const sellerSignContractWithBuyer = createAsyncThunk(
  "/seller/signcontractwithbuyerr",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.post(
        `/seller/signcontractwithbuyer`,
        payload,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const updateSigningStatusContract = createAsyncThunk(
  "/contract/updateSigningStatus",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.post(
        `/contract/updateSigningStatus`,
        payload,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const getpropertyofferbyid = createAsyncThunk(
  "seller/getpropertyofferbyid",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(
        `/seller/getpropertyofferbyid/${payload}`,
        config
      );
      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const acceptmeetingtimefrombuyer = createAsyncThunk(
  "seller/acceptmeetingtimefrombuyer",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.post(
        `/seller/acceptmeetingtimefrombuyer`,
        payload,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");

      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");

      return toast.error(error?.response?.data?.message);
    }
  }
);

export const declinebuyertimeandsendnew = createAsyncThunk(
  "seller/declinebuyertimeandsendnew",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/seller/declinebuyertimeandsendnew`,
        payload,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const selectNotaryOption = createAsyncThunk(
  "seller/selectNotaryOption",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/seller/selectnotaryoption`,
        payload,
        config
      );
      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const proceedwithsellingpropertyoffer = createAsyncThunk(
  "seller/proceedwithsellingpropertyoffer",
  async (payload, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/seller/proceedwithsellingpropertyoffer`,
        payload,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const postpublicreviewforbuyer = createAsyncThunk(
  "seller/postpublicreviewforbuyer",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");
      const token = Cookie.get("userToken");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/seller/postpublicreviewforbuyer`,
        payload,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return error;
    }
  }
);

export const postreviewforvirtualassistant = createAsyncThunk(
  "seller/postreviewforvirtualassistant",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.post(
        `/seller/postreviewforvirtualassistant`,
        payload,
        config
      );
      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return error;
    }
  }
);

export const propertyPromoted = createAsyncThunk(
  "seller/propertyPromoted",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");
      const token = getToken();

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.post(
        `/property/pro/promoteproperty`,
        payload,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateProfilePic = createAsyncThunk(
  "user/updateProfilePic",
  async (picture, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const sellerId = getLocalStorageItem("activePersonaDetails")?.sellerPersonaId;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await instance.put(
        `/seller/${sellerId}/images`,
        picture,
        config
      );

      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteProfilePic = createAsyncThunk(
  "seller/deleteProfilePic",
  async (_, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const personaId = getLocalStorageItem("activePersonaDetails")?.sellerPersonaId;

      const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { fileType: "profilePicture" },
      };

      const response = await instance.delete(
        `/seller/${personaId}/images`,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const getSellerProfile = createAsyncThunk(
  "/seller/getsellerprofile",
  async (userId, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.get(
        `/seller/profile-by-id/${userId}`,
        config
      );
      return response;
    } catch (error) {
      // toast.error(error?.response?.data?.message)
    }
  }
);

export const getAtomChatConfig = createAsyncThunk(
  "/chats/user-status/get-atomchat-config",
  async () => {
    try {
      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await instance.get(
        `/chats/user-status/get-atomchat-config`,
        config
      );
      return response;
    } catch (error) {
      // toast.error(error?.response?.data?.message)
    }
  }
);

export const updateVirtualTourAPI = createAsyncThunk(
  "seller/updateVirtualTourAPI",
  updateVirtualTourApiThunkFn
);

const sellerSlice = createSlice({
  name: "seller",
  initialState,
  reducers: {
    toggleVirtualTour: (state, action) => {
      const show = action?.payload.show;
      return {
        ...state,
        showVirtualTour: show ?? !state.showVirtualTour,
      };
    },
    sellerInformation: (state, action) => {
      return {
        ...state,
        information: {
          ...state.information,
          ...action?.payload,
        },
      };
    },
    resetInformationTab: (state, action) => {
      return {
        ...state,
        information: defaultPropertiesInInformationTab,
        visualPropertyTab: propertyPhotos,
        price: "",
      };
    },
    sellerVisuals: (state, action) => {
      return {
        ...state,
        visualPropertyTab: {
          ...state.visualPropertyTab,
          ...action?.payload,
        },
      };
    },
    resetVisualsTab: (state, action) => {
      return {
        ...state,
        visualPropertyTab: propertyPhotos,
      };
    },

    setVirtualTour: (state, action) => {
      return {
        ...state,
        virtualTours: [
          {
            ...state.virtualTours[0],
            ...(action.payload?.virtualTourId && {
              virtualTourId: action.payload?.virtualTourId,
            }),
            ...(action?.payload?.panoramas && {
              panoramas: action.payload?.panoramas,
            }),
            ...(action?.payload?.floorPlan && {
              floorPlan: action?.payload?.floorPlan,
            }),
          },
        ],
      };
    },
    updateVirtualTour: (state, action) => {
      const { panoramas, floorPlan } = action?.payload;
      return {
        ...state,
        virtualTours: [
          {
            ...state.virtualTours[0],
            ...(panoramas && { panoramas }),
            ...(floorPlan && { floorPlan }),
          },
        ],
      };
    },
    resetVirtualTour: (state, action) => {
      return {
        ...state,
        virtualTour: {
          panoramas: [],
          floorPlan: [],
        },
        virtualTours: [],
      };
    },
    renamePanorama: (state, action) => {
      const panoId = action?.payload?.panoId;
      const panoIndex = state.virtualTours[0]?.panoramas?.findIndex(
        (el) => el.id === panoId
      );
      if (panoIndex < 0) return { ...state };
      return {
        ...state,
        virtualTours: [
          {
            ...state.virtualTours[0],
            panoramas: [
              ...state.virtualTours[0].panoramas.slice(0, panoIndex),
              {
                ...state.virtualTours[0]?.panoramas[panoIndex],
                panoramaName: action?.payload?.panoramaName,
              },
              ...state.virtualTours[0]?.panoramas.slice(panoIndex + 1),
            ],
          },
        ],
      };
    },
    updatePanorama: (state, action) => {
      const { virtualTourId, panoId, ...updates } = action?.payload;
      const tourIndex = state.virtualTours.findIndex(
        (el) => el.virtualTourId === virtualTourId
      );
      if (tourIndex < 0) return { ...state };
      const panoIndex = state.virtualTours[tourIndex]?.panoramas.findIndex(
        (el) => el.id === panoId
      );
      if (panoIndex < 0) return { ...state };
      return {
        ...state,
        virtualTours: [
          ...state.virtualTours.slice(0, tourIndex),
          {
            ...state.virtualTours[tourIndex],
            panoramas: [
              ...state.virtualTours[tourIndex]?.panoramas.slice(0, panoIndex),
              {
                ...state.virtualTours[tourIndex]?.panoramas[panoIndex],
                ...updates,
              },
              ...state.virtualTours[tourIndex]?.panoramas.slice(panoIndex + 1),
            ],
          },
          ...state.virtualTours.slice(tourIndex + 1),
        ],
      };
    },
    updateFloorPlan: (state, action) => {
      const { virtualTourId, floorPlan } = action?.payload;
      const tourIndex = state.virtualTours.findIndex(
        (el) => el.virtualTourId === virtualTourId
      );
      if (tourIndex < 0 && !virtualTourId) return { ...state };
      if (tourIndex < 0) {
        return {
          ...state,
          virtualTours: [
            ...state.virtualTours,
            {
              virtualTourId,
              panoramas: [],
              floorPlan,
            },
          ],
        };
      }
      return {
        ...state,
        virtualTours: [
          ...state.virtualTours.slice(0, tourIndex),
          {
            ...state.virtualTours[tourIndex],
            floorPlan,
          },
          ...state.virtualTours.slice(tourIndex + 1),
        ],
      };
    },
    property360Walk: (state, action) => {
      return {
        ...state,
        visualPropertyTab: {
          ...state.visualPropertyTab,
          threeSixty: [
            ...state.visualPropertyTab.threeSixty,
            ...(Array.isArray(action?.payload)
              ? action?.payload
              : [action?.payload]),
          ],
        },
      };
    },
    resetProperty360Walk: (state, action) => {
      return {
        ...state,
        visualPropertyTab: {
          ...state.visualPropertyTab,
          threeSixty: [],
        },
      };
    },
    sellerPrice: (state, action) => {
      return {
        ...state,
        price: action?.payload,
      };
    },
    sellerFinalPrice: (state, action) => {
      return {
        ...state,
        finalPrice: action?.payload,
      };
    },
    sellerFeePayer: (state, action) => {
      return {
        ...state,
        feePayer: action?.payload,
      };
    },
    sellerDocuments: (state, action) => {
      return {
        ...state,
        documents: {
          ...state.documents,
          ...action?.payload,
        },
      };
    },
    sellerPropertyPlan: (state, action) => {
      return {
        ...state,
        propertyPlan: action?.payload,
      };
    },
    sellerRedirectNotification: (state, action) => {
      return {
        ...state,
        notificationRedirect: action?.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProperty.pending,(state)=>{
        state.isLoadingData = true
        state.loading = "loading";
      })
      .addCase(updateProperty.fulfilled,(state)=>{
        state.isLoadingData = false
        state.loading = "loaded";
      })
      .addCase(updateProperty.rejected,(state)=>{
        state.isLoadingData = false
        state.loading = "rejected";
      })
      .addCase(listSellerProperties.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(listSellerProperties.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        if (payload.data) {
          state.propertyList = [...payload.data.result];
          state.propertyPaginationDetails = {
            totalItems: payload.data.totalItems,
            totalPages: payload.data.totalPages,
            currentPage: payload.data.currentPage,
          };
        }
      })
      .addCase(listSellerProperties.rejected, (state, action) => {
        const { error } = action?.meta?.arg || {
          error: { message: "Something went wrong" },
        };
        state.loading = "rejected";
        state.error = error;
        state.propertyList = [];
      })
      .addCase(acceptOffer.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(acceptOffer.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        state.offerStatus = "accepted";
        state.offer = payload;
      })
      .addCase(acceptOffer.rejected, (state, action) => {
        state.loading = "rejected";
      })
      .addCase(refuseOffer.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(refuseOffer.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        state.offerStatus = "offer declined";
        state.offer = payload;
      })
      .addCase(refuseOffer.rejected, (state, action) => {
        state.loading = "rejected";
      })

      .addCase(sendRequestVirtualAssistant.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(sendRequestVirtualAssistant.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        state.requestStatus = true;
      })
      .addCase(sendRequestVirtualAssistant.rejected, (state, action) => {
        state.loading = "rejected";
      })

      .addCase(getpropertyofferbyid.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getpropertyofferbyid.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        state.offer = payload?.data;
      })
      .addCase(getpropertyofferbyid.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
      })
      .addCase(getAtomChatConfig.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getAtomChatConfig.fulfilled, (state, { payload }) => {
        state.loading = "loaded";
        state.atomChatConfig = payload?.data;
      })
      .addCase(getAtomChatConfig.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
      })
      .addCase(updateVirtualTourAPI.pending, (state) => {
        state.isLoadingData = true;
      })
      .addCase(updateVirtualTourAPI.rejected, (state) => {
        state.isLoadingData = false;
      })
      .addCase(updateVirtualTourAPI.fulfilled, (state, { payload }) => {
        state.isLoadingData = false;
        if (Array.isArray(payload.data.data)) {
          if (
            payload.data.data.some(
              (el) => el.virtualTourId === state.virtualTours[0].virtualTourId
            )
          ) {
            state.virtualTours = [
              {
                ...shallowCopy(
                  payload.data.data.find(
                    (el) =>
                      el.virtualTourId === state.virtualTours[0].virtualTourId
                  )
                ),
              },
            ];
          }
        }
      });
  },
});

export const {
  toggleVirtualTour,
  sellerInformation,
  resetInformationTab,
  sellerVisuals,
  resetVisualsTab,
  setVirtualTour,
  updateVirtualTour,
  resetVirtualTour,
  renamePanorama,
  updatePanorama,
  updateFloorPlan,
  property360Walk,
  resetProperty360Walk,
  sellerPrice,
  sellerDocuments,
  sellerPropertyPlan,
  sellerFinalPrice,
  sellerFeePayer,
  sellerRedirectNotification,
  filterSellerProperties,
} = sellerSlice.actions;
export default sellerSlice.reducer;
