import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    paper: {
      marginRight: theme.spacing(2),
    },
    faSearch: {
      float: "left",
      margin: "8px 5px 0 11px",
      color: "#000",
      width: "18px",
      height: "18px",
    },
    iconAddProperty: {
      float: "left",
      color: "#000",
      width: "16px",
      height: "16px",
    },
  }));