/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  notifications,
  readNotifications,
} from "../../../features/user/authSlice";
import { photographerRedirectNotification } from "../../../features/user/photographerSlice";
import SideBarMenu from "./HeaderPhotographerSideBar";

import "./HeaderPhotographer.css";

import { Button } from "@material-ui/core";

// icons
import { FaSearch } from "react-icons/fa";
import { IconContext } from "react-icons/lib";

// svg
import Images from "../../../assets/uploaded_images.json";

import PlacesAutocomplete from "react-places-autocomplete";

import {
  filtersProperty,
  geocodeAddressLocation,
} from "../../../features/property/propertySlice";
import Notifications from "./../../common/Notifications/Notification";
import {
  listServiceRequests,
  resetServiceRequest,
} from "../../../features/common/serviceRequestSlice";
import { LangSwitcher } from "../../common/LangSwitcher/LangSwitcher";
import { validateInput } from "./../../../helpers/userInputEscaper";
import { toast } from "react-toastify";
import { getLocalStorageItem } from "../../../config/localStorageEncryption";
import Cookie from "js-cookie";
const { useStyles } = require("./styles");
const AccountGroup = Images["account-group.svg"];
const Account = Images["account-header.svg"];
const Logo = Images["Logo.svg"];

const HeaderPhotographer = () => {
  const { t } = useTranslation();
  const colorValue = useMemo(() => ({ color: "#fff" }), []);

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const messageRef = useRef(null);

  const persona = getLocalStorageItem("activePersonaDetails");
  const photographerPersonaId = persona.photographerPersonaId;
  const [address, setAddress] = useState("");
  const [openNotificaration, setOpenNotification] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("activePersonaDetails");
    localStorage.removeItem("authenticatedUser");
    localStorage.removeItem("activePersona");
    localStorage.removeItem("user");
    localStorage.setItem("currentTokenNotification", "");
    localStorage.removeItem("virtualAssistantRefLink");
    Cookie.remove('userToken');
    Cookie.remove("activerPersona")
    window.location.pathname = "/login";
  };

  const handleClick = () => {
    navigate("/search-properties");
  };

  const handleSelect = async (value) => {
    let resultAddress = await geocodeAddressLocation(value);
    if (
      (!resultAddress?.formatted_address &&
        !resultAddress?.latitude &&
        !resultAddress?.longitude &&
        !resultAddress?.municipality &&
        !resultAddress?.region) ||
      !resultAddress?.continent
    ) {
      toast.error("Please choose another suitable and accurate address");
    } else {
      let newSearchQuery = {
        address: value,
      };
      if (resultAddress?.municipality) {
        newSearchQuery.municipality = resultAddress?.municipality;
      }
      if (resultAddress?.region) {
        newSearchQuery.region = resultAddress?.region;
      }
      if (resultAddress?.continent) {
        newSearchQuery.continent = resultAddress?.continent;
      }
      if (resultAddress?.country) {
        newSearchQuery.country = resultAddress?.country;
      }

      dispatch(filtersProperty(newSearchQuery));
      setAddress("");
      navigate("/search-properties");
    }
  };

  const handleClickNotification = (item) => {
    let redirectUrl = "";
    if (item?.notificationType === "cancelledJobRequest") {
      dispatch(
        listServiceRequests({
          providerId: photographerPersonaId,
          serviceType: "photography",
        })
      );
      dispatch(resetServiceRequest());
      redirectUrl =
        "/photographer/dashboard?persona=" + item?.senderPersona + "s";
    } else if (item?.notificationType === "serviceReview") {
      dispatch(
        listServiceRequests({
          providerId: photographerPersonaId,
          serviceType: "photography",
        })
      );
      dispatch(resetServiceRequest());
      redirectUrl =
        "/photographer/dashboard?persona=" + item?.senderPersona + "s";
    } else if (item?.notificationType === "receivePublicReview") {
      redirectUrl = "third-party/photographer-profile/" + photographerPersonaId;
    } else if (item?.notificationType === "receivePayment") {
      dispatch(
        listServiceRequests({
          providerId: photographerPersonaId,
          serviceType: "photography",
        })
      );
      dispatch(resetServiceRequest());
      redirectUrl =
        "/photographer/dashboard?persona=" + item?.senderPersona + "s";
    } else if (item?.notificationType === "paidAgreedAmount") {
      dispatch(
        listServiceRequests({
          providerId: photographerPersonaId,
          serviceType: "photography",
        })
      );
      dispatch(resetServiceRequest());
      redirectUrl =
        "/photographer/dashboard?persona=" + item?.senderPersona + "s";
    } else if (item?.notificationType === "virtualTourReview") {
      dispatch(
        listServiceRequests({
          providerId: photographerPersonaId,
          serviceType: "photography",
        })
      );
      dispatch(resetServiceRequest());
      redirectUrl =
        "/photographer/dashboard?persona=" + item?.senderPersona + "s";
    } else if (item?.notificationType === "virtualTourApproved") {
      dispatch(
        listServiceRequests({
          providerId: photographerPersonaId,
          serviceType: "photography",
        })
      );
      dispatch(resetServiceRequest());
      redirectUrl =
        "/photographer/dashboard?persona=" + item?.senderPersona + "s";
    }

    if (item?.notificationType === "receiveJobRequest") {
      dispatch(
        listServiceRequests({
          providerId: photographerPersonaId,
          serviceType: "photography",
        })
      );
      dispatch(resetServiceRequest());
      redirectUrl = "/photographer/dashboard?section=inquiries";
    }
    dispatch(photographerRedirectNotification(redirectUrl));

    dispatch(readNotifications(item?.notificationId)).then((r) => {
      if (r?.payload?.data?.status) {
        setOpenNotification(false);
        dispatch(notifications());
      }
    });
  };

  return (
    <header className="header-photographer">
      <IconContext.Provider value={colorValue}>
        <nav className="navbar">
          <div className="clearfix" style={{ width: "100%" }}>
            <Link to="/photographer" className="navbar-logo">
              <img loading="lazy" src={Logo} alt="" />
            </Link>

            <div className="lang-switcher-desk">
              <div className="display-translation-switcher">
                <LangSwitcher />
              </div>
            </div>

            <ul className="nav-menu">
              <li className="nav-item-lang-switcher">
                <LangSwitcher />
              </li>
              <li className="nav-item">
                <Link to="/photographer" className="nav-links">
                  {" "}
                  {t("Начало")}{" "}
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/photographer/dashboard" className="nav-links">
                  {" "}
                  {t("Продавачи")}{" "}
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/third-party?lawyers" className="nav-links">
                  {" "}
                  {t("Професионалисти")}{" "}
                </Link>
              </li>
            </ul>

            <form className="search">
              <PlacesAutocomplete
                value={validateInput(address)}
                onChange={setAddress}
                onSelect={handleSelect}
                className="search"
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <FaSearch
                      className={classes.faSearch}
                      style={{ color: "#0097A7" }}
                      onClick={handleClick}
                    />
                    <input
                      className="search-field"
                      {...getInputProps({ placeholder: `${t("Търси имоти")}` })}
                      style={{
                        padding: "20px",
                        fontSize: "16px",
                        color: "#000",
                        width: "100%",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    />

                    <div className="drop-down">
                      {loading ? <div>...loading</div> : null}

                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#00BCD4"
                            : "#ffffff",
                          fontSize: "18px",
                          color: "#00bcd4",
                          padding: "13px",
                        };

                        return (
                          <div
                            key={suggestion.placeId}
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </form>

            <div className="right-part">
              <div className="account-group">
                <Link to="/photographer/dashboard" className="clearfix link">
                  <img loading="lazy" src={AccountGroup} alt="" />

                  <p className="title">{t("Моите клиенти")}</p>
                </Link>
              </div>
              <div className="notification-icon">
                <Notifications
                  handleNotification={handleClickNotification}
                  setOpenNotification={setOpenNotification}
                  openNotificaration={openNotificaration}
                  messageRef={messageRef}
                />
              </div>
              <div className="bell bell-desktop">
                <Link to={`/photographer/my-profile/${photographerPersonaId}`}>
                  <img loading="lazy" src={Account} className="icon" alt="" />
                </Link>
              </div>

              <Button onClick={handleLogout} className="logout-button">
                {" "}
                {t("Изход")}{" "}
              </Button>
              <div className="photographer-sidenav">
                <SideBarMenu photographerPersonaId={photographerPersonaId} />
              </div>
            </div>
          </div>
        </nav>
      </IconContext.Provider>
    </header>
  );
};

export default HeaderPhotographer;
