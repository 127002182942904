/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, Dialog, DialogContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Images from "../../../assets/uploaded_images.json";
import {
  changeEmailsSubscriptionDetails,
  emailsSubscriptionDetails,
} from "../../../features/common/emailsSubscriptionSlice";
import "./EmailsSubscription.css";
import { getLocalStorageItem } from "../../../config/localStorageEncryption";
const CloseModal = Images["close-modal.svg"];
const Unsubscribe = Images["delete.svg"];
const Check = Images["check-white.svg"];

const mapState = ({ emailsSubscriptionSlice }) => ({
  isOpenModal: emailsSubscriptionSlice?.emailsSubscriptionDetails?.isOpenModal,
});

const EmailsSubscription = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isOpenModal } = useSelector(mapState);

  const authenticatedUser = getLocalStorageItem("authenticatedUser")
    ? getLocalStorageItem("authenticatedUser")
    : {};
  const activePersona = authenticatedUser?.activePersona;
  const [subscribedEmailNotifications, setSubscribedEmailNotifications] =
    useState([]);
  const [unsubscribedEmailNotifications, setUnsubscribedEmailNotifications] =
    useState([]);

  const currentUnsubscribedEmailsNotifications =
    authenticatedUser?.unsubscribedEmailNotifications
      ? authenticatedUser?.unsubscribedEmailNotifications
      : [];

  let allEmailNotifications = null;
  if (activePersona === "seller") {
    allEmailNotifications = {
      listingAprrovedEmail: {
        title: "Одобрен имот",
        description: "Бъди известен при успешно публикуване на имот",
      },
      sellerCommissionChangedEmail: {
        title: "Промяна на комисионата като продавач",
        description: "Бъди известен при промяна на комисионата, която дължиш",
      },
      offerReceivedEmail: {
        title: "Получена предварителна оферта",
        description:
          "Бъди известен когато получиш оферта от купувач за твой имот",
      },
      suggestAppointmentTimeFrameEmail: {
        title: "Нов час за оглед",
        description: "Бъди известен при получено запитване за оглед",
      },
      acceptAppointmentTimeFrameEmail: {
        title: "Прието запитване за оглед",
        description: "Бъди известен при прието запитване за оглед",
      },
      serviceRequest: {
        title: "Успешно завършена услуга",
        description:
          "Бъди известен всеки път когато адвокат или фотограф завърши успешно услугата ти",
      },
    };
  } else if (activePersona === "buyer") {
    allEmailNotifications = {
      buyerCommissionChangedEmail: {
        title: "Промяна на комисионата като купувач",
        description: "Бъди известен при промяна на комисионата, която дължиш",
      },
      depositPaidEmail: {
        title: "Платен депозит",
        description: "Бъди известен при капариране на твой имот от купувач",
      },
      offerAcceptedEmail: {
        title: "Приета оферта",
        description: "Бъди известен когато продавач приеме твоя оферта",
      },
      suggestAppointmentTimeFrameEmail: {
        title: "Нов час за оглед",
        description: "Бъди известен при получено запитване за оглед",
      },
      acceptAppointmentTimeFrameEmail: {
        title: "Прието запитване за оглед",
        description: "Бъди известен при прието запитване за оглед",
      },
      sellerChangeDateViewingEmail: {
        title: "Запитване на промяна за оглед",
        description:
          "Бъди известен при поискана промяна на датата и часа за оглед",
      },
      serviceRequest: {
        title: "Успешно завършена услуга",
        description:
          "Бъди известен всеки път когато адвокат или фотограф завърши успешно услугата ти",
      },
    };
  } else if (activePersona === "photographer") {
    allEmailNotifications = {
      newServiceRequest: {
        title: "Запитване за услуга",
        description: "Бъди известен при всяко ново запитване от клиент",
      },
    };
  } else if (activePersona === "lawyer") {
    allEmailNotifications = {
      newServiceRequest: {
        title: "Запитване за услуга",
        description: "Бъди известен при всяко ново запитване от клиент",
      },
    };
  } else if (activePersona === "virtualAssistant") {
    allEmailNotifications = {
      newServiceRequest: {
        title: "Запитване за услуга",
        description: "Бъди известен при всяко ново запитване от клиент",
      },
    };
  } else {
    allEmailNotifications = null;
  }
  useEffect(() => {
    let allSubscribedNotifications = [];
    let allUnsubscribedNotifications = [];

    if (allEmailNotifications) {
      Object.keys(allEmailNotifications).forEach((emailNotification) => {
        if (
          currentUnsubscribedEmailsNotifications.includes(emailNotification)
        ) {
          allUnsubscribedNotifications.push(
            allEmailNotifications[emailNotification]
          );
        } else {
          allSubscribedNotifications.push(
            allEmailNotifications[emailNotification]
          );
        }
      });
    } else {
      return;
    }

    setSubscribedEmailNotifications(allSubscribedNotifications);
    setUnsubscribedEmailNotifications(allUnsubscribedNotifications);
  }, []);

  const handleClose = () => {
    dispatch(
      emailsSubscriptionDetails({
        isOpenModal: false,
      })
    );
  };

  const handleContinue = () => {
    const newUnsubscribedEmailsTitles = unsubscribedEmailNotifications.map(
      ({ title }) => title
    );

    const newUnsubscribedEmails = [];

    Object.keys(allEmailNotifications).forEach((key) => {
      if (
        newUnsubscribedEmailsTitles.includes(allEmailNotifications[key].title)
      ) {
        newUnsubscribedEmails.push(key);
      }
    });

    const data = {
      unsubscribedEmailNotifications: newUnsubscribedEmails,
    };

    if (
      newUnsubscribedEmails.length ===
        currentUnsubscribedEmailsNotifications.length &&
      newUnsubscribedEmails.every((value) =>
        currentUnsubscribedEmailsNotifications.includes(value)
      )
    ) {
      handleClose();
    } else {
      dispatch(changeEmailsSubscriptionDetails(data)).then((r) => {
        if (r?.result) {
          dispatch(
            emailsSubscriptionDetails({
              isOpenModal: false,
            })
          );
        }
      });
    }
  };

  useEffect(() => {
    if (isOpenModal) {
      const delay = 100;
      const timeoutId = setTimeout(() => {}, delay);

      return () => clearTimeout(timeoutId);
    }
  }, [isOpenModal]);

  const handleSwitchEmailNotification = (i, from, to) => {
    if (from === "unsubscribed") {
      let emailNot = unsubscribedEmailNotifications[i];
      setUnsubscribedEmailNotifications((oldArray) => {
        let newArr = [...oldArray];
        return newArr;
      });
      setSubscribedEmailNotifications((oldArray) => {
        let newArr = [...oldArray];
        newArr.push(emailNot);
        return newArr;
      });
    } else {
      let emailNot = subscribedEmailNotifications[i];
      setSubscribedEmailNotifications((oldArray) => {
        let newArr = [...oldArray];
        return newArr;
      });
      setUnsubscribedEmailNotifications((oldArray) => {
        let newArr = [...oldArray];
        newArr.push(emailNot);
        return newArr;
      });
    }
  };
  return (
    <Dialog
      disableScrollLock={true}
      className="dialog-common-email-subscriptions"
      onClose={handleClose}
      open={isOpenModal}
      fullWidth={true}
      maxWidth="md"
      PaperProps={{
        style: {
          background: "#FFFFFF",
          boxShadow: "0px 4px 32px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
          width: "600px",
        },
      }}
    >
      <div className="dialog-common-email-subscriptions-content">
        <div className="title-header">
          <h4 className="title">{t("Имейл известия")}</h4>
          <img
            src={CloseModal}
            className="close-icon"
            alt=""
            onClick={handleClose}
          />
        </div>
        <DialogContent className="content">
          <p className="sub-title">
            {t(
              "Един отличен начин да бъдете винаги в течение е чрез нашата услуга за известия. Получавайте персонализирани известия за новини, актуални събития и специални предложения, директно във вашата пощенска кутия. Използвайте нашите известия, за да бъдете винаги информирани и да не пропускате важна информация."
            )}
          </p>
          <div
            className="cols cols-full subscriptions-container subscriptions-container-card-grid"
            style={{ userSelect: "none" }}
          >
            <div className="top-row">
              <h1 className="category">{t("Абониран")}</h1>

              {subscribedEmailNotifications.length > 0
                ? subscribedEmailNotifications.map((emailNotification, i) => (
                    <div className="subscriptions-container-card" key={emailNotification?.title}>
                      <div className="email-type">
                        <h1 className="title">{t(emailNotification?.title)}</h1>
                        <p className="text">
                          {t(emailNotification?.description)}
                        </p>
                      </div>
                      <div
                        className="action-button"
                        style={{ backgroundColor: "red" }}
                        onClick={() =>
                          handleSwitchEmailNotification(
                            i,
                            "subscribed",
                            "unsubscribed"
                          )
                        }
                      >
                        <img
                          src={Unsubscribe}
                          width={20}
                          className="icon"
                          alt=""
                        />
                      </div>
                    </div>
                  ))
                : "You don't have subscribed emails"}
            </div>
            <div className="top-row">
              <h1 className="category">{t("отменен абонамент")}</h1>

              {unsubscribedEmailNotifications.length > 0
                ? unsubscribedEmailNotifications.map((emailNotification, i) => (
                    <div
                      className="subscriptions-container-card"
                      key={emailNotification?.title}
                    >
                      <div className={"email-type"}>
                        <h1 className="title">{t(emailNotification?.title)}</h1>
                        <p className="text">
                          {t(emailNotification?.description)}
                        </p>
                      </div>
                      <div
                        className="action-button"
                        style={{ backgroundColor: "#00838F" }}
                        onClick={() =>
                          handleSwitchEmailNotification(
                            i,
                            "unsubscribed",
                            "subscribed"
                          )
                        }
                      >
                        <img loading="lazy" src={Check} width={20} className="icon" alt="" />
                      </div>
                    </div>
                  ))
                : "You don't have unsubscribed emails"}
            </div>
          </div>
          <div className="actions">
            <Button onClick={handleContinue} className="button-continue">
              {t("спаси")}
            </Button>
            <Button onClick={handleClose} className="button-back">
              {t("Назад")}
            </Button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default EmailsSubscription;
