/* eslint-disable react-hooks/exhaustive-deps */
import "./Imports";
import React, { useEffect, useState } from "react";
import "./App.css";
import Routes from "./routes";
import ThemeConfig from "./theme";

import isMobile from "ismobilejs";
import { useLocation, useNavigate } from "react-router-dom";

import SmoothScroll from "smooth-scroll";

import { ToastContainer, toast } from "react-toastify";
import Contract from "./components/common/Contract/Contract";
import Multiprofile from "./components/common/Multiprofile/Multiprofile";

import {
  saveFCMtoken,
  occasionalUpdateUserCountry,
  notifications,
  changeIsUserLocationAddressAvailable,
} from "./features/user/authSlice";
import { useDispatch, useSelector } from "react-redux";


import {
  getLawyerStripeStatus,
  lawyerRedirectNotification,
} from "./features/user/lawyerSlice";
import { buyerRedirectNotification } from "./features/user/buyerSlice";
import {
  getPhotographerStripeStatus,
  photographerRedirectNotification,
} from "./features/user/photographerSlice";
import {
  getVirtualAssistantStripeStatus,
  virtualAssistantRedirectNotification,
} from "./features/user/virtualAssistantSlice";
import { socialLoginRefreshToken } from "./features/user/authSlice";
import EmailsSubscription from "./components/common/UnsubscribeEmailsModal/EmailsSubscription";
import { emailsSubscriptionDetails } from "./features/common/emailsSubscriptionSlice";
import AddToHomeScreenBanner from "./components/common/AddToHomeScreenBanner/AddToHomeScreenBanner";
import { getAbidorsStatistics } from "./features/common/statisticsSlice";
import VAEducationVideoModal from "./components/common/VAEducationVideoModal/VAEducationVideoModal";
import ProgressBar from "./components/common/ProgressBar/ProgressBar";
import {
  processSocialLoginResult,
  isTokenExpired,
} from "./utils/processSocialLoginData";
import { removeRecaptchaBadge } from "./utils/googleRecaptcha";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "./config/localStorageEncryption";
import {
  checkLocalStorageSpace,
  getUserLocationDetailByName,
  setUserLang,
  updateUserLocationDetailWeekly,
} from "./hooks/useTranslator";

import { locationPrev } from './features/property/propertySlice'
import Images from "./assets/uploaded_images.json";
import PropertyViewModal from "./components/common/PropertyViewModal/PropertyViewModal";
import PropertyImageViewModal from "./components/common/PropertyViewModal/PropertyImagViewModal/PropertyImageViewModal";
import MetaPixel from "./config/MetaPixel";
const AbidorsPin = Images["abidorsPin.png"];
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const responseParam = urlParams.get("response");
  const parsedData = JSON.parse(responseParam);
  setLocalStorageItem("socialResponse", parsedData);
  
  const badge = document.querySelector(".grecaptcha-badge");

  let iPhoneDevice = isMobile(window.navigator).apple.phone;
  let FirebaseNotifications = iPhoneDevice
    ? window.navigator.standalone === true
      ? null
      : import("./firebase")
    : import("./firebase");
  let canImportFirebase = iPhoneDevice
    ? window.navigator.standalone === true
      ? false
      : true
    : true;
  const location = useLocation();
  const splitedLocation = location?.pathname?.split("/");
  const navigate = useNavigate();
  const [showFirebaseBanner, setShowFirebaseBanner] = useState(true);
  const dispatch = useDispatch();

  const socialLoginResult = getLocalStorageItem("socialResponse");

  const activePersona = getLocalStorageItem("authenticatedUser")
    ? getLocalStorageItem("authenticatedUser")?.activePersona
    : null;
  let redirectDispatcher;
  const isUserLocationAddressAvailable = useSelector(
    (state) => state?.authenticatedUser?.isUserLocationAddressAvailable
  );
  const { notificationRedirect } = useSelector((state) => {
    if (activePersona === "lawyer") {
      redirectDispatcher = lawyerRedirectNotification;
      return {
        notificationRedirect: state.lawyerSlice.notificationRedirect,
      };
    } else if (activePersona === "photographer") {
      redirectDispatcher = photographerRedirectNotification;
      return {
        notificationRedirect: state.photographerSlice.notificationRedirect,
      };
    } else if (
      activePersona === "virtualassistant" ||
      activePersona === "virtualAssistant"
    ) {
      redirectDispatcher = virtualAssistantRedirectNotification;
      return {
        notificationRedirect: state.virtualAssistantSlice.notificationRedirect,
      };
    } else if (activePersona === "buyer") {
      redirectDispatcher = buyerRedirectNotification;
      return {
        notificationRedirect: state.buyerSlice.notificationRedirect,
      };
    } else if (activePersona === "seller") {
      redirectDispatcher = buyerRedirectNotification;
      return {
        notificationRedirect: state.sellerSlice.notificationRedirect,
      };
    }
    // Return a default value or handle the case when activePersona is not set
    redirectDispatcher = null;
    return {
      notificationRedirect: "",
    };
  });
  let getUserStripeStatus;
  switch (activePersona) {
    case "photographer":
      getUserStripeStatus = getPhotographerStripeStatus;
      break;
    case "virtualassistant":
      getUserStripeStatus = getVirtualAssistantStripeStatus;
      break;
    case "lawyer":
      getUserStripeStatus = getLawyerStripeStatus;
      break;
    default:
      getUserStripeStatus = null;
  }

  const [notification, setNotification] = useState({ title: "", body: "" });
  const [getFcmToken, setFcmToken] = useState("");
  const isSupported = () =>
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window;
  async function requirePermissions() {
    if (isSupported()) {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        FirebaseNotifications?.then((firebaseObject) => {
          firebaseObject.fetchToken(setFcmToken);
        });
      }
    }
  }

  useEffect(() => {
    if (canImportFirebase) {
      requirePermissions();
    }
  }, []);

  const [currentLocation, setCurrentLocation] = useState(null);
  const [prevLocation, setPrevLocation] = useState(null);

  useEffect(() => {
    setPrevLocation(currentLocation);
    setCurrentLocation(location);
  }, [location]);

  useEffect(() => {
    dispatch(locationPrev({
      prevLocation: prevLocation,
      currentLocation: currentLocation
    }))
  }, [prevLocation, currentLocation])

  if (canImportFirebase) {
    FirebaseNotifications?.then((firebaseObject) => {
      firebaseObject
        ?.onMessageListener()
        .then((payload) => {
          setNotification({
            title: payload?.notification?.title,
            body: payload?.notification?.body,
          });
        })
        .catch((err) => toast.error("failed: " + err), {
          autoClose: true,
          position: "top-right",
          bodyClassName: "styled-toaster",
          delay: 0,
        });
    });
  }

  useEffect(() => {
    if (getLocalStorageItem("authenticatedUser")) {
      if (location?.pathname === "/" || location?.pathname === "/null") {
        if (activePersona) {
          if (activePersona === "virtualassistant") {
            navigate("/virtual");
          } else {
            navigate(`/${activePersona}`);
          }
        } else {
          navigate("/");
        }
      }
    }

    if (getUserStripeStatus) {
      dispatch(getUserStripeStatus());
    }
  }, []);

  useEffect(() => {
    if (
      splitedLocation[1] === "user" &&
      splitedLocation[2] === "verification" &&
      splitedLocation[3] === "resetpassword"
    ) {
      return navigate(`/confirm-new-password?${splitedLocation[4]}`);
    }

    if (
      splitedLocation[1] === "user" &&
      splitedLocation[2] === "verification"
    ) {
      return navigate(`/email/verification?${splitedLocation[3]}`);
    }
    if (
      location?.search?.split("?").includes("showAlertsSubsciptionModal") &&
      activePersona !== null
    ) {
      dispatch(
        emailsSubscriptionDetails({
          isOpenModal: true,
        })
      );
    }
  }, []);

  useEffect(() => {
    let activePersonaUserId = "";

    if (getLocalStorageItem("activePersonaDetails")) {
      activePersonaUserId = getLocalStorageItem("activePersonaDetails")?.userId;
    }

    let data = {
      userId: activePersonaUserId,
      fcmToken: getLocalStorageItem("currentTokenNotification"),
    };

    if (getLocalStorageItem("currentTokenNotification")) {
      if (getFcmToken?.length > 0 && activePersonaUserId) {
        dispatch(saveFCMtoken(data));
      }
    }
  }, [getFcmToken]);

  useEffect(() => {
    if (
      location?.pathname !== "/seller/my-adds" &&
      getLocalStorageItem("propertyId")
    ) {
      localStorage?.removeItem("propertyId");
    }

    const splittedPathname = location.pathname.split("/");

    if (splittedPathname?.length > 1) {
      const firstVal = splittedPathname[1];
      let isTypeOfPersonPage = false;

      if (
        firstVal === "seller" ||
        firstVal === "buyer" ||
        firstVal === "photographer" ||
        firstVal === "lawyer" ||
        firstVal === "virtualassistant"
      ) {
        isTypeOfPersonPage = true;
      }

      if (
        isTypeOfPersonPage &&
        activePersona !== firstVal &&
        (!splittedPathname[2] || splittedPathname[2] !== "public-profile")
      ) {
        if (activePersona === "virtualassistant") {
          navigate(`/virtual`);
        } else {
          navigate(`/${activePersona}`);
        }
      }
    }
  }, [location]);

  useEffect(() => {
    let isMounted = true;

    const showNotification = () => {
      if (isMounted && notification?.title) {
        toast.info(`${notification.title} ${notification.body}`);
      }
    };

    if (getLocalStorageItem("authenticatedUser")) {
      dispatch(notifications());
      showNotification();
    }

    return () => {
      isMounted = false;
      // Perform any cleanup if needed
    };
  }, [notification]);

  useEffect(() => {
    if (notificationRedirect !== "") {
      navigate(notificationRedirect);
      dispatch(redirectDispatcher(""));
    }
  }, [notificationRedirect]);

  useEffect(() => {
    getUserLocationDetailByName("countryName").then((countryName) => {
      updateUserLocationDetailWeekly().then((userAddressUpToDate) => {
        if (userAddressUpToDate) {
          dispatch(changeIsUserLocationAddressAvailable(true));
          dispatch(occasionalUpdateUserCountry());
          if (socialLoginResult && countryName) {
            processSocialLoginResult(socialLoginResult, countryName);
          }
          dispatch(getAbidorsStatistics());
          checkLocalStorageSpace();
          setUserLang();
        }
      });
    });
  }, []);

  useEffect(() => {
    let provider = "";

    if (socialLoginResult?.facebookConnectId) {
      provider = "facebook";
    } else if (socialLoginResult?.googleConnectId) {
      provider = "google";
    } else if (socialLoginResult?.linkedinConnectId) {
      provider = "linkedin";
    } else if (socialLoginResult?.appleConnectId) {
      provider = "apple";
    }

    if (
      socialLoginResult !== null &&
      isTokenExpired(socialLoginResult?.token)
    ) {
      const obj = {
        userId: socialLoginResult?.userId,
        provider: provider,
      };

      dispatch(socialLoginRefreshToken(obj));
    }
  }, [socialLoginResult]);

  useEffect(() => {
    if (badge) {
      removeRecaptchaBadge(location?.pathname);
    }
  }, [badge]);

  return isUserLocationAddressAvailable ? (
    <>
      <ThemeConfig>
        <Routes />
        <MetaPixel />
        <div className="container"> </div>
        <ToastContainer style={{ zIndex: 9999 }} />
        <Contract />
        <EmailsSubscription />
        <Multiprofile />
        <VAEducationVideoModal />
        <ProgressBar />
        <PropertyViewModal />
        <PropertyImageViewModal />
      </ThemeConfig>
      {iPhoneDevice &&
        window.navigator.standalone === false &&
        showFirebaseBanner && (
          <AddToHomeScreenBanner
            setShowFirebaseBanner={setShowFirebaseBanner}
          />
        )}
    </>
  ) : (
    <div className="loading-container">
      <img loading="lazy" src={AbidorsPin} alt="loading..." className="loading-image" />
    </div>
  );
};

export default App;
