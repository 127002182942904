import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Masonry from "@mui/lab/Masonry";
import { styled } from "@mui/material/styles";
import { propertyViewDetails } from "../../../../features/common/propertyViewSlice";
import { useDispatch } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "./MasonryGallery.css"
const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

export default function MasonryGallery({ images }) {
  const dispatch = useDispatch();
  const handleViewImage = (image) => {
    dispatch(
      propertyViewDetails({
        isViewingImageModalOpen: true,
        activePropertyImage: image,
      })
    );
  };
const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ width: "100%", minHeight: "100%"}}>
      <Masonry columns={fullScreen?1:2} spacing={1} className="masonry">
        {images.map((image, index) => (
          <div key={index}>
            <img
              srcSet={`${image}?w=162&auto=format&dpr=2 2x`}
              src={`${image}?w=162&auto=format`}
              loading="lazy"
              style={{
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                display: "block",
                width: "100%",
                backgroundColor: "#80DEEA",
              }}
              onClick={() => handleViewImage(image)}
            />
          </div>
        ))}
      </Masonry>
    </Box>
  );
}
