import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

const PrintResults = ({ array, handleRedirect }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {array?.length
        ? array.map((result, i) => (
            <h3
              key={`${result.city}${i}`}
              className="cities"
              onClick={() => handleRedirect(result.city)}
            >
              {t(result?.city)}
              {` ${result?.propertyCount && result?.propertyCount>0 ? `(${result?.propertyCount})` : ""}`}
            </h3>
          ))
        : ""}
    </Fragment>
  );
};

export default PrintResults;
