import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, Dialog, DialogContent } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

import Images from "../../../assets/uploaded_images.json";
import { toast } from "react-toastify";
import {
  multiprofileDetails,
  changeActivePersona,
} from "../../../features/common/mutiprofile.Slice";
import { useTranslation } from "react-i18next";
import {getProperVirtualEducationVideo} from "../../../helpers/getProperVirtualEducationVideo"

import "./Multiprofile.css";
import { changeBuyerCurrentServiceRequest, changeSellerCurrentServiceRequest } from "../../../features/common/serviceRequestSlice";
import { getLocalStorageItem, setLocalStorageItem } from "../../../config/localStorageEncryption";
import { divideInto } from "../../../utils";
import { IoIosWarning } from "react-icons/io";
import { userGetPersonaStatus } from "../../../features/user/authSlice";
import Cookie from "js-cookie";
const CloseModal = Images["close-modal.svg"];
const Note = Images["note.svg"];
const House = Images["house.svg"];
const VideoPlayIcon = Images["play.svg"];
const HouseFull = Images["house-full.svg"];
const Line = Images["line.svg"];
const SmallLine = Images["small-line.svg"];
const Briefcase = Images["briefcase.svg"];
const ClipboardAccount = Images["clipboard-account.svg"];
const Camera = Images["camera.svg"];
const DollarSign = Images["dollar-sign.svg"];
const mapState = ({ multiprofileSlice }) => ({
  isOpenModal: multiprofileSlice?.multiprofileDetails?.isOpenModal,
});

const Multiprofile = () => {
  const dispatch = useDispatch();
  const { isOpenModal } = useSelector(mapState);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const properVideo = getProperVirtualEducationVideo()

  const activePersona = getLocalStorageItem("authenticatedUser")
    ? getLocalStorageItem("authenticatedUser")?.activePersona
    : {};
  const [activeCard, setActiveCard] = useState();
  const [personaStatus, setPersonaStatus] = useState({
    lawyer: true,
    virtualassistant: true,
    photographer: true
  })

  useEffect(() => {
    const token = Cookie.get('userToken');
    if(token){

      dispatch(userGetPersonaStatus()).then(({ payload }) => {
        const { data } = payload;
        setPersonaStatus(data.data)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePlayPause = () => {
    dispatch(
      multiprofileDetails({
        showVAEducationVideo: true,
      })
    );
  };

  const handleClose = () => {
    dispatch(
      multiprofileDetails({
        isOpenModal: false,
      })
    );
  };

  const handleContinue = () => {
    let data = {
      activePersona: activeCard,
    };

    if (activeCard === activePersona) {
      return toast.error(t("Не може да изберете дадената персона"));
    }

    if (!getLocalStorageItem("authenticatedUser")) {
      setLocalStorageItem("activePersona", activeCard);
      handleClose();
      navigate("/registration");
    } else {
      dispatch(changeActivePersona(data)).then((r) => {
        dispatch(changeBuyerCurrentServiceRequest({}))
        dispatch(changeSellerCurrentServiceRequest({}))
        if (r?.result) {
          dispatch(
            multiprofileDetails({
              isOpenModal: false,
            })
          );
        }
      });
    }
  };

  const toggleActiveCard = (card) => {
    if (card && activePersona !== card) {
      setActiveCard(card);
    }
  };

  const profileCards = useMemo(() => {
    return {
      separator: <div className="col col2of12 "></div>,
      // numberOfSeparators: 1,
      separateAfter: 3,
      cards: [
        {
          persona: 'buyer',
          title: "Купувач",
          description: "Намери имота, който отговаря напълно на твоите виждания и желания.",
          graphic: <><img loading="lazy" src={Note} className="icon" alt="" />
            <div className="parts">
              <img
                src={HouseFull}
                style={{ marginLeft: "5px", marginBottom: "3px" }}
                alt=""
              />
              <img loading="lazy" src={Line} style={{ marginBottom: "3px" }} alt="" />
              <img loading="lazy" src={Line} style={{ marginBottom: "3px" }} alt="" />
              <img
                src={SmallLine}
                style={{ marginLeft: "0.5px", marginBottom: "3px" }}
                alt=""
              />
            </div></>
        },
        {
          persona: 'seller',
          title: "Продавач",
          description: "Имаш достъп до многобройни потребители. Това значително улеснява продажбата на имот.",
          graphic: <>
            <img loading="lazy" src={House} className="icon" alt="" />
            <div className="parts">
              <img
                src={DollarSign}
                style={{ margin: "15px", height: "25px" }}
                alt=""
              />
            </div></>
        },
        {
          persona: 'lawyer',
          title: "Адвокат",
          description: "Предложи услугите си на продавачите и купувачите. Печели допълнителни доходи.",
          graphic: <>
            <img loading="lazy" src={Briefcase} className="icon" alt="" />
          </>,
          extra_content: <>
          {
            !personaStatus.lawyer && <div className="profile-card-reactivation-warning">
              <IoIosWarning color="gold" />
              <span>{t('В момента е деактивирано, ще бъде активирано отново автоматично, ако бъде избрано')}</span>
            </div>
          }
        </>
        },
        {
          persona: 'virtualassistant',
          title: "Виртуален асистент",
          description: "Помагай на продавачите и купувачите. Печели винаги, когато има сделка",
          graphic: <>
            <img loading="lazy" src={ClipboardAccount} className="icon" alt="" />
          </>,
          extra_content: <>
            <div className="video">
              <div className="video-play-button-container">
                <img
                  className="video-play-button"
                  onClick={handlePlayPause}
                  src={VideoPlayIcon}
                  alt=""
                />
              </div>
              {<video
                className="video-play"
                controlsList="nodownload"
              >
                <source type="video/mp4" src={properVideo} />
              </video>}
            </div>
            <>
            {
              !personaStatus.virtualassistant && <div className="profile-card-reactivation-warning">
                <IoIosWarning color="gold" />
                <span>{t('В момента е деактивирано, ще бъде активирано отново автоматично, ако бъде избрано')}</span>
              </div>
            }
          </>
          </>
        },
        {
          persona: 'photographer',
          title: "Фотограф",
          description: "Отключете нови възможности за развитие на вашата фотографска дейност! Предложете страхотни снимки, видеа и 360 виртуални разходки на продавачите и увеличете вашия доход.",
          graphic: <>
            <img loading="lazy" src={Camera} className="icon" alt="" />
          </>,
          extra_content: <>
            {
              !personaStatus.photographer && <div className="profile-card-reactivation-warning">
                <IoIosWarning color="gold" />
                <span>{t('В момента е деактивирано, ще бъде активирано отново автоматично, ако бъде избрано')}</span>
              </div>
            }
          </>
        }
      ]
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personaStatus])


  return (
    <Dialog
      disableScrollLock={true}
      className="dialog-common-multi-profile"
      onClose={handleClose}
      open={isOpenModal}
      fullWidth={true}
      maxWidth="md"
      PaperProps={{
        style: {
          background: "#FFFFFF",
          boxShadow: "0px 4px 32px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
        },
      }}
    >
      <div className="dialog-common-multi-profile-content">
        <div className="title-header">
          <h4 className="title">{t("Мултипрофил")}</h4>
          <img
            src={CloseModal}
            className="close-icon"
            alt=""
            onClick={handleClose}
          />
        </div>
        <DialogContent className="content">
          <p className="sub-title">
           {t("Мултипрофилът ти позволява да използваш един профил с няколко различни цели. Едновременно можеш да бъдеш купувач, продавач, фотограф и виртуален асистент. Ако притежаваш необходимите знания и компетентност, то може да участваш в сделки и като адвокат.")}
          </p>
          <div
            className="cols cols-full profiles-container profiles-container-card-grid"
            style={{ userSelect: "none" }}
          >
            {
              divideInto(profileCards.cards, profileCards.separateAfter, profileCards.separator).map((subset, subset_i) => {
                if(subset_i%2) return subset
                return (
                  <div key={subset_i} className={subset_i > 1 ? "bottom-row" : "top-row" }>
                    {
                      subset.map((profileCard, i) => (
                        <div key={i} className="col col4of12 profiles-container-card">
                          <div
                            className={`box ${profileCard.persona} ${activePersona === profileCard.persona
                              ? "disabled"
                              : `${activeCard === profileCard.persona ? "active" : ""}`
                              }`}
                            onClick={() => toggleActiveCard(profileCard.persona)}
                          >
                            {profileCard.graphic}
                            <h1 className="title">{t(profileCard.title)}</h1>
                            <p className="text">
                              {t(profileCard.description)}
                              {profileCard.extra_content}
                            </p>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                )
              })
            }

            <div className="col col2of12"></div>
          </div>
          <div className="actions">
            <Button
              onClick={handleContinue}
              className="button-continue"
              disabled={!activeCard}
            >
              {t("Продължи")}
            </Button>
            <Button onClick={handleClose} className="button-back">
              {t("Назад")}
            </Button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default Multiprofile;
