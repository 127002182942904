import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
      display: "flex"
  },
  paper: {
      marginRight: theme.spacing(2)
  },
  faSearch: {
      float: 'left',
      margin: '11px',
      color: '#000',
      width: '18px',
      height: '18px'
  },
  iconAddProperty: {
      float: 'left',
      color: '#000',
      width: '16px',
      height: '16px'
  },
  notchedOutline: {
      borderWidth: "1px",
      borderColor: "#EDEDED !important",
      color: '#EDEDED'
  },
  dialog: {
    position: "absolute",
    top: 20,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
  },
  content: {
    width: "100%",
    padding: 0,
  },
}));