import React, { useEffect, useRef, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  notifications,
  readNotifications,
} from "../../../features/user/authSlice";
import { virtualAssistantRedirectNotification } from "../../../features/user/virtualAssistantSlice";
import "./HeaderVirtualAssistant.css";

import { Button } from "@material-ui/core";

/* icons */
import { FaSearch } from "react-icons/fa";
import { IconContext } from "react-icons/lib";

/* svg */
import Images from "../../../assets/uploaded_images.json";

import PlacesAutocomplete from "react-places-autocomplete";

import {
  filtersProperty,
  geocodeAddressLocation,
} from "../../../features/property/propertySlice";
import { getVirtualAssistantCertifications } from "../../../features/user/virtualAssistantSlice";
import Notifications from "./../../common/Notifications/Notification";
import {
  listServiceRequests,
  resetServiceRequest,
} from "../../../features/common/serviceRequestSlice";
import { LangSwitcher } from "../../common/LangSwitcher/LangSwitcher";
import { useTranslation } from "react-i18next";
import SideBar from "./sideBar";
import { useMediaQuery } from "react-responsive";
import { validateInput } from "./../../../helpers/userInputEscaper";
import { toast } from "react-toastify";
import { getUserLocationDetailByName } from "../../../hooks/useTranslator";
import { getLocalStorageItem } from "../../../config/localStorageEncryption";
import Cookie from "js-cookie";
const { useStyles } = require("./styles");
const AccountGroup = Images["account-group.svg"];
const Account = Images["account-header.svg"];
const TextBoxCheckOutline = Images["text-box-check-outline.svg"];
const Logo = Images["Logo.svg"];

const HeaderVirtualAssistant = () => {
  const showMenu = useMediaQuery({
    query: "(max-width: 1127px)",
  });

  const hideSearch = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const colorValue = useMemo(() => ({ color: "#fff" }), []);
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userCountry, setUserCountry] = useState();
  useEffect(() => {
    getUserLocationDetailByName("countryName").then((countryName) => {
      setUserCountry(countryName);
    });
  }, []);
  const persona = getLocalStorageItem("activePersonaDetails");
  const virtualAssistantId = persona?.virtualAssistantId;

  const [address, setAddress] = useState("");

  const [openNotificaration, setOpenNotification] = useState(false);
  const messageRef = useRef(null);

  useEffect(() => {
    dispatch(getVirtualAssistantCertifications(virtualAssistantId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualAssistantId]);

  const handleLogout = () => {
    localStorage.removeItem("activePersonaDetails");
    localStorage.removeItem("authenticatedUser");
    localStorage.removeItem("activePersona");
    localStorage.removeItem("user");
    localStorage.setItem("currentTokenNotification", "");
    localStorage.removeItem("virtualAssistantRefLink");
    Cookie.remove('userToken');
    Cookie.remove("activerPersona")
    window.location.pathname = "/login";
  };

  const handleClick = () => {
    navigate("/search-properties");
  };

  const handleSelect = async (value) => {
    let resultAddress = await geocodeAddressLocation(value);
    if (
      !resultAddress?.formatted_address &&
      !resultAddress?.latitude &&
      !resultAddress?.longitude &&
      !resultAddress?.municipality &&
      !resultAddress?.region &&
      !resultAddress?.continent
    ) {
      toast.error("Please choose another suitable and accurate address");
    } else {
      let newSearchQuery = {
        address: value,
      };
      if (resultAddress?.municipality) {
        newSearchQuery.municipality = resultAddress?.municipality;
      }
      if (resultAddress?.region) {
        newSearchQuery.region = resultAddress?.region;
      }
      if (resultAddress?.continent) {
        newSearchQuery.continent = resultAddress?.continent;
      }
      if (resultAddress?.country) {
        newSearchQuery.country = resultAddress?.country;
      }

      dispatch(filtersProperty(newSearchQuery));
      setAddress("");
      navigate("/search-properties");
    }
  };

  const handleClickNotification = (item) => {
    let redirectUrl = "";
    if (item?.notificationType === "cancelledJobRequest") {
      dispatch(
        listServiceRequests({
          providerId: virtualAssistantId,
          serviceType: "assistance",
        })
      );
      dispatch(resetServiceRequest());
      redirectUrl = "/virtual/dashboard?" + item?.senderPersona + "s";
    } else if (item?.notificationType === "receivePublicReview") {
      redirectUrl =
        "third-party/virtual-assistant-profile/" + virtualAssistantId;
    } else if (item?.notificationType === "receivePayment") {
      dispatch(
        listServiceRequests({
          providerId: virtualAssistantId,
          serviceType: "assistance",
        })
      );
      dispatch(resetServiceRequest());
      redirectUrl = "/virtual/dashboard?persona=" + item?.senderPersona + "s";
    }
    if (item?.notificationType === "signViaReferralLink") {
      dispatch(
        listServiceRequests({
          providerId: virtualAssistantId,
          serviceType: "assistance",
        })
      );
      dispatch(resetServiceRequest());
      redirectUrl = "/virtual/dashboard?section=inquiries";
    }
    if (item?.notificationType === "receivePayment") {
      redirectUrl = `/property-details?${item.propertyId}`;
    }
    dispatch(virtualAssistantRedirectNotification(redirectUrl));

    dispatch(readNotifications(item?.notificationId)).then((r) => {
      if (r?.payload?.data?.status) {
        setOpenNotification(false);
        dispatch(notifications());
      }
    });
  };

  return (
    <header className="header-virtual-assistant">
      <IconContext.Provider value={colorValue}>
        <nav className="navbar">
          <div className="clearfix" style={{ width: "100%" }}>
            <Link to="/virtual" className="navbar-logo">
              <img loading="lazy" src={Logo} alt="" />
            </Link>
            <ul className="nav-menu">
              <li className="languages">
                <LangSwitcher />
              </li>
              <li className="nav-item">
                <Link to="/virtual" className="nav-links">
                  {" "}
                  {t("Начало")}{" "}
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/virtual/dashboard?customers=buyers"
                  className="nav-links"
                >
                  {" "}
                  {t("Купувачи")}{" "}
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/virtual/dashboard?customers=sellers"
                  className="nav-links"
                >
                  {" "}
                  {t("Продавачи")}{" "}
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/third-party?lawyers" className="nav-links">
                  {" "}
                  {t("Професионалисти")}{" "}
                </Link>
              </li>
            </ul>

            {!hideSearch && (
              <form className="search">
                <PlacesAutocomplete
                  value={validateInput(address)}
                  onChange={setAddress}
                  onSelect={handleSelect}
                  className="search"
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <FaSearch
                        className={classes.faSearch}
                        style={{ color: "#0097A7" }}
                        onClick={handleClick}
                      />
                      <input
                        className="search-field"
                        {...getInputProps({
                          placeholder: `${t("Търси имоти")}`,
                        })}
                        style={{
                          padding: "20px",
                          fontSize: "16px",
                          color: "#000",
                          width: "100%",
                          border: "none",
                          borderRadius: "8px",
                        }}
                      />

                      <div className="drop-down">
                        {loading ? <div>...loading</div> : null}
                        {suggestions?.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#00BCD4"
                              : "#ffffff",
                            fontSize: "18px",
                            color: suggestion.active ? "#ffffff" : "#00bcd4",
                            padding: "13px",
                          };

                          return (
                            <div
                              key={suggestion.placeId}
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </form>
            )}

            <div className="right-part">
              {userCountry && hideSearch && userCountry !== "Bulgaria" && (
                <div className="languages">
                  <LangSwitcher />
                </div>
              )}
              {getLocalStorageItem("activePersonaDetails") ? (
                getLocalStorageItem("activePersonaDetails")?.isCertified ? (
                  <div className="account-group">
                    <Link
                      to="/virtual/dashboard?buyers"
                      className="clearfix link"
                    >
                      <img loading="lazy" src={AccountGroup} alt="" />

                      <p className="title">{t("Моите клиенти")}</p>
                    </Link>
                  </div>
                ) : (
                  <div className="account-group">
                    <Link to="/virtual/education" className="clearfix link">
                      <img loading="lazy" src={TextBoxCheckOutline} alt="" />

                      <p className="title">{t("Изкарай теста")}</p>
                    </Link>
                  </div>
                )
              ) : null}
              <div className="notification-icon">
                <Notifications
                  handleNotification={handleClickNotification}
                  setOpenNotification={setOpenNotification}
                  openNotificaration={openNotificaration}
                  messageRef={messageRef}
                />
              </div>

              {!hideSearch && (
                <div className="bell">
                  <Link to={`/virtual/my-profile/${virtualAssistantId}`}>
                    <img loading="lazy" src={Account} className="icon" alt="" />
                  </Link>
                </div>
              )}
              {!showMenu && (
                <>
                  <Button onClick={handleLogout} className="va-logout-button">
                    {" "}
                    {t("Изход")}{" "}
                  </Button>
                </>
              )}
              {showMenu && (
                <div className="sidebar-menu-icon">
                  <SideBar virtualAssistantId={virtualAssistantId} />
                </div>
              )}
            </div>
          </div>
        </nav>
      </IconContext.Provider>
    </header>
  );
};

export default HeaderVirtualAssistant;
