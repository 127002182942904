
const loadRecaptchaScript =()=>{
return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.NODE_ENV==="development"?process.env.REACT_APP_RECAPTCHA_SITE_KEY_DEV_V3:process.env.REACT_APP_RECAPTCHA_SITE_KEY_PROD_V3}`;
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

const executeRecaptcha = async (action) => {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(async () => {
      try {
        const token = await window.grecaptcha.execute(process.env.NODE_ENV==="development"?process.env.REACT_APP_RECAPTCHA_SITE_KEY_DEV_V3:process.env.REACT_APP_RECAPTCHA_SITE_KEY_PROD_V3, { action });
        resolve(token);
      } catch (error) {
        reject(error);
      }
    });
  });
};

const removeRecaptchaBadge = (pathname) => {

  const allowedPathNames =["/registration","/contact"]
if(!allowedPathNames.includes(pathname)){

    const badge = document.querySelector('.grecaptcha-badge');
    if (badge) {
      badge.style.display = 'none';
    }
  
}else{
  return
}

};

export { loadRecaptchaScript, executeRecaptcha,removeRecaptchaBadge };