import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookie from "js-cookie";
import instance from "../../config/axiosConfig";
import { toast } from "react-toastify";
import { shallowCopy, updateVirtualTourApiThunkFn } from "../../utils";
import { getLocalStorageItem } from "../../config/localStorageEncryption";

const initialState = {
  loading: "idle",
  profileUpdateSuccess: false,
  portfolioUpdateSuccess: false,
  error: null,
  photographerActivePersona: null,
  photographerProfile: null,
  photographerDetails: {
    picturePortfolio: [],
  },
  photographerServices: null,
  photographerReviews: null,
  notificationRedirect: "",
  stripeStatus: null,
  virtualTours: [],
  property360Walk: [],
  selectedVirtualTourId: null,
  isSavingVirtualTourKey: null,
};

const getToken = () => Cookie.get("userToken");

export const getPhotographerStripeStatus = createAsyncThunk(
  "photographer/stripeStatus",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(`/payment/stripeStatus`, config);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getPhotographerProfile = createAsyncThunk(
  "photographer/getPhotographerProfile",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(`/photographers/${payload}`, config);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createStripeAccount = createAsyncThunk(
  "photographer/createStripeAccount",
  async (_, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const photographerProfileDetils = getLocalStorageItem("activePersonaDetails");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(
        `/photographers/${photographerProfileDetils?.photographerPersonaId}/stripe`,
        config
      );

      document.getElementById("loading-indicator").classList.remove("open");

      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);
export const viewPhotographerStripeDashbaord = createAsyncThunk(
  "photographer/viewStripeAccount",
  async (_, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");

      const token = Cookie.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(`/payment/loginLink`, config);

      document.getElementById("loading-indicator").classList.remove("open");

      return response;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const updatePhotographerProfile = createAsyncThunk(
  "photographer/updatePhotographerProfile",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const { photographerId, formData } = payload;
      const response = await instance.post(
        `/photographers/${photographerId}`,
        formData,
        config
      );
      thunkAPI.dispatch(getPhotographerProfile(photographerId));
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createPhotographerService = createAsyncThunk(
  "photographer/createPhotographerService",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await instance.post(
        `/photographer-service/createService`,
        payload,
        config
      );
      return await instance.get(
        `/photographer-service/getAllPhotographerServices`,
        payload.photographerId,
        config
      );
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllPhotographerServices = createAsyncThunk(
  "photographer/getAllPhotographerServices",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(`/services`, payload, config);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updatePhotographerService = createAsyncThunk(
  "photographer/updatePhotographerService",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.post(
        `/photographer-service/updatePhotographerService`,
        payload,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deletePhotographerService = createAsyncThunk(
  "photographer/deletePhotographerService",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await instance.post(
        `/photographer-service/deletePhotographerService`,
        payload,
        config
      );
      return payload;
      // return await instance.get(
      // 	`/photographer-service/getAllPhotographerServices`, payload.photographerId, config
      // )
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Return all reviews specific to a photographer
export const getPhotographerReviews = createAsyncThunk(
  "photographer/getPhotographerReviews",
  async (payload, thunkAPI) => {
    try {
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.get(
        `/reviews/?type=photographer&typeID=${payload}`,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Change the profile photo of a photographer

export const updateProfilePicture = createAsyncThunk(
  "photographer/updateProfilePicture",
  async (payload, thunkAPI) => {
    try {
      const { photographerId, picture } = payload;
      const token = getToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await instance.put(
        `/photographers/${photographerId}/images`,
        picture,
        config
      );
      thunkAPI.dispatch(getPhotographerProfile(photographerId)); // input correct values for payload.
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteProfilePicture = createAsyncThunk(
  "seller/deleteProfilePic",
  async (_, thunkAPI) => {
    try {
      const token = Cookie.get("userToken");

      const personaId = getLocalStorageItem("activePersonaDetails")?.photographerPersonaId;

      const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { fileType: "profilePicture" },
      };

      const response = await instance.delete(
        `/photographers/${personaId}/images`,
        config
      );
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

// Upload photographer portfolio images
export const uploadPortfolioImages = createAsyncThunk(
  "photographer/uploadPortfolioImages",
  async (payload, thunkAPI) => {
    try {
      const { photographerId, images } = payload;
      const token = getToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await instance.put(
        `/photographers/${photographerId}/images`,
        images,
        config
      );
      thunkAPI.dispatch(getPhotographerProfile(photographerId)); // input correct values for payload.
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Upload photographer portfolio images
export const uploadPortfolioVideos = createAsyncThunk(
  "photographer/uploadPortfolioVideos",
  async (payload, thunkAPI) => {
    try {
      const { photographerId, videos } = payload;
      const token = getToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await instance.put(
        `/photographers/${photographerId}/videos`,
        videos,
        config
      );
      thunkAPI.dispatch(getPhotographerProfile(photographerId)); // input correct values for payload.
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Upload photographer portfolio 360deg videos
export const uploadPortfolio360Videos = createAsyncThunk(
  "photographer/uploadPortfolio360Videos",
  async (payload, thunkAPI) => {
    try {
      const { photographerId, videos } = payload;
      const token = getToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await instance.put(
        `/photographers/${photographerId}/videos`,
        videos,
        config
      );
      // thunkAPI.dispatch(getPhotographerProfile(photographerId)); // input correct values for payload.
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Delete photographer portfolio items
export const deletePortfolioItems = createAsyncThunk(
  "photographer/deletePortfolioItems",
  async (payload, thunkAPI) => {
    try {
      const { photographerId, fileType, fileName, portfolioType } = payload;
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let queryString = `fileType=${fileType}`;
      if (fileName) {
        queryString = queryString + `&fileName=${fileName}`;
      }
      const response = await instance.delete(
        `/photographers/${photographerId}/${portfolioType}?${queryString}`,
        config
      );
      thunkAPI.dispatch(getPhotographerProfile(photographerId)); // input correct values for payload.
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteVirtualTour = createAsyncThunk(
  "photographer/deleteVirtualTour",
  async (payload, thunkAPI) => {
    try {
      const { virtualTourId } = payload;
      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await instance.delete(
        `/virtual-tours/${virtualTourId}`,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/* Get all inquiry to a specific photographer*/
export const getAllPhotographerQuiry = createAsyncThunk(
  "photographer/getAllPhotographerQuiry",
  async (payload, thunkAPI) => {
    try {
      let photographerId = "";

      const token = getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      if (getLocalStorageItem("activePersonaDetails")) {
        photographerId = getLocalStorageItem("activePersonaDetails")?.photographerPersonaId;
      }

      const response = await instance.get(
        `/service-requests?providerId=${photographerId}&providerType=photographer`,
        payload,
        config
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const saveVirtualTour = createAsyncThunk(
  "photographer/saveVirtualTour",
  async (payload, thunkAPI) => {
    document.getElementById("loading-indicator").classList.add("open");
    try {
      let photographerId = "";
      let { body, config = {} } = payload;
      const token = getToken();
      config = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      if (getLocalStorageItem("activePersonaDetails")) {
        photographerId = getLocalStorageItem("activePersonaDetails")?.photographerPersonaId;
      }

      const response = await instance.post(
        `photographers/${photographerId}/360walks`,
        body,
        config
      );
      document.getElementById("loading-indicator").classList.remove("open");
      return response;
    } catch ({ message: error }) {
      document.getElementById("loading-indicator").classList.remove("open");
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const updateVirtualTourAPI = createAsyncThunk(
  "photographer/updateVirtualTourAPI",
  updateVirtualTourApiThunkFn
);

const photographerSlice = createSlice({
  name: "photographer",
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetProfileUpdateState: (state) => {
      state.profileUpdateSuccess = false;
    },
    resetPortfolioUpdateState: (state) => {
      state.portfolioUpdateSuccess = false;
    },
    setVirtualTours: (state, action) => {
      state.virtualTours = action?.payload;
    },
    updatePhotographerPortfolioImages: (state, action) => {
      state.photographerDetails.picturePortfolio =
        action?.payload?.picturePortfolio;
    },
    photographerRedirectNotification: (state, action) => {
      return {
        ...state,
        notificationRedirect: action?.payload,
      };
    },
    addVirtualTour: function (state, action) {
      if (
        state.virtualTours.some(
          (el) => el.virtualTourId === action?.payload.virtualTourId
        )
      ) {
        return photographerSlice.caseReducers.updateVirtualTour(state, action);
      }
      return {
        ...state,
        virtualTours: [...state.virtualTours, action?.payload],
      };
    },
    removeVirtualTour: (state, action) => {
      const { virtualTourId } = action?.payload;
      const tourIndex = state.virtualTours.findIndex(
        (el) => el.virtualTourId === virtualTourId
      );

      if (tourIndex < 0) return { ...state };
      return {
        ...state,
        virtualTours: [
          ...state.virtualTours.slice(0, tourIndex),
          ...state.virtualTours.slice(tourIndex + 1),
        ],
      };
    },
    updateVirtualTour: (state, action) => {
      const { virtualTourId, ...updates } = action?.payload;
      const tourIndex = state.virtualTours.findIndex(
        (el) => el.virtualTourId === virtualTourId
      );

      if (tourIndex < 0) return { ...state };
      return {
        ...state,
        virtualTours: [
          ...state.virtualTours.slice(0, tourIndex),
          {
            ...state.virtualTours[tourIndex],
            ...updates,
          },
          ...state.virtualTours.slice(tourIndex + 1),
        ],
      };
    },
    updatePanorama: (state, action) => {
      const { virtualTourId, panoId, ...updates } = action?.payload;
      const tourIndex = state.virtualTours.findIndex(
        (el) => el.virtualTourId === virtualTourId
      );
      if (tourIndex < 0) return { ...state };
      const panoIndex = state.virtualTours[tourIndex]?.panoramas.findIndex(
        (el) => el.id === panoId
      );
      if (panoIndex < 0) return { ...state };
      return {
        ...state,
        virtualTours: [
          ...state.virtualTours.slice(0, tourIndex),
          {
            ...state.virtualTours[tourIndex],
            panoramas: [
              ...state.virtualTours[tourIndex]?.panoramas.slice(0, panoIndex),
              {
                ...state.virtualTours[tourIndex]?.panoramas[panoIndex],
                ...updates,
              },
              ...state.virtualTours[tourIndex]?.panoramas.slice(panoIndex + 1),
            ],
          },
          ...state.virtualTours.slice(tourIndex + 1),
        ],
      };
    },
    updateFloorPlan: (state, action) => {
      const { virtualTourId, floorPlan } = action?.payload;
      const tourIndex = state.virtualTours.findIndex(
        (el) => el.virtualTourId === virtualTourId
      );
      if (tourIndex < 0 && !virtualTourId) return { ...state };
      if (tourIndex < 0) {
        return {
          ...state,
          virtualTours: [
            ...state.virtualTours,
            {
              virtualTourId,
              panoramas: [],
              floorPlan,
            },
          ],
        };
      }
      return {
        ...state,
        virtualTours: [
          ...state.virtualTours.slice(0, tourIndex),
          {
            ...state.virtualTours[tourIndex],
            floorPlan,
          },
          ...state.virtualTours.slice(tourIndex + 1),
        ],
      };
    },
    property360Walk: (state, action) => {
      return {
        ...state,
        property360Walk: [
          ...state.property360Walk,
          ...(Array.isArray(action?.payload)
            ? action?.payload
            : [action?.payload]),
        ],
      };
    },
    selectVirtualTour: (state, action) => {
      return {
        ...state,
        selectedVirtualTourId: action?.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPhotographerProfile.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getPhotographerProfile.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.photographerDetails = action.payload.data;
        if (action.payload.data.virtualTours) {
          state.virtualTours = action.payload.data.virtualTours
            .map(shallowCopy)
            .map((el) => ({
              ...el,
              alreadyExists: true,
            }));
        }
      })
      .addCase(getPhotographerProfile.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
      })

      .addCase(updatePhotographerProfile.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(updatePhotographerProfile.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.profileUpdateSuccess = true;
        toast.success("Photographer Profile Update Successful", {
          position: "bottom-right",
        });
      })
      .addCase(updatePhotographerProfile.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
        console.log("error found: ");
      })

      .addCase(createPhotographerService.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(createPhotographerService.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.photographerServices = action.payload.data.data;
      })
      .addCase(createPhotographerService.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
        console.log("error found: ");
        console.error(action.error.message);
      })

      .addCase(getAllPhotographerServices.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getAllPhotographerServices.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.photographerServices = action.payload.data;
      })
      .addCase(getAllPhotographerServices.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
      })

      .addCase(updatePhotographerService.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(
        updatePhotographerService.fulfilled,
        (state, getAllPhotographerServices) => {
          state.loading = "loaded";
          toast.success("Photographer Service Update Successful", {
            position: "bottom-right",
          });
        }
      )
      .addCase(updatePhotographerService.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
      })

      .addCase(deletePhotographerService.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(deletePhotographerService.fulfilled, (state, action) => {
        state.loading = "loaded";
        // state.photographerServices = action.payload.data.data
        state.photographerServices = state.photographerServices.filter(
          (service) =>
            service.photographerServiceId !==
            action.payload.photographerServiceId
        );
        toast.error("Photographer Service Deleted", {
          position: "bottom-right",
        });
      })
      .addCase(deletePhotographerService.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
      })

      .addCase(getPhotographerReviews.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getPhotographerReviews.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.photographerReviews = action.payload.data;
      })
      .addCase(getPhotographerReviews.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
      })

      .addCase(uploadPortfolioImages.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(uploadPortfolioImages.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.portfolioUpdateSuccess = true;
        toast.success("Photographer Image Portfolio Update Successful", {
          position: "bottom-right",
        });
      })
      .addCase(uploadPortfolioImages.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
        console.log("error found: ");
      })

      .addCase(uploadPortfolioVideos.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(uploadPortfolioVideos.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.portfolioUpdateSuccess = true;
        toast.success("Photographer Video Portfolio Update Successful", {
          position: "bottom-right",
        });
      })
      .addCase(uploadPortfolioVideos.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
        console.log("error found: ");
      })

      .addCase(uploadPortfolio360Videos.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(uploadPortfolio360Videos.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.portfolioUpdateSuccess = true;
        toast.success("Photographer 360 Video Portfolio Update Successful", {
          position: "bottom-right",
        });
      })
      .addCase(uploadPortfolio360Videos.rejected, (state, action) => {
        const { error } = action.meta.arg;
        state.loading = "rejected";
        state.error = error;
        console.log("error found: ");
      })
      .addCase(getPhotographerStripeStatus.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getPhotographerStripeStatus.fulfilled, (state, action) => {
        state.loading = "loaded";
        state.stripeStatus = action.payload.data.result;
      })
      .addCase(getPhotographerStripeStatus.rejected, (state, action) => {
        state.loading = "rejected";
        console.log("error found: ");
      })
      .addCase(saveVirtualTour.fulfilled, (state, action) => {
        state.isSavingVirtualTourKey = null;
        state.virtualTours = action.payload.data.virtualTours
          .map(shallowCopy)
          .map((el) => ({
            ...el,
            alreadyExists: true,
          }));
        toast.success("New Virtual Tour(s) saved successfully", {
          autoClose: 2000,
        });
      })
      .addCase(saveVirtualTour.rejected, (state, action) => {
        state.isSavingVirtualTourKey = null;
        toast.error("Failed to Save New Virtual Tour(s)", { autoClose: 2000 });
      });
  },
});

export const {
  reset,
  resetProfileUpdateState,
  resetPortfolioUpdateState,
  photographerRedirectNotification,
  addVirtualTour,
  removeVirtualTour,
  updateVirtualTour,
  updatePanorama,
  updateFloorPlan,
  property360Walk,
  selectVirtualTour,
  updatePhotographerPortfolioImages,
} = photographerSlice.actions;
export default photographerSlice.reducer;
