import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../config/axiosConfig";
import Cookie from "js-cookie";

const SLICE_URL = "/service-requests";

const getToken = () => Cookie.get("userToken");
const token = getToken();
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

const initialState = {
  serviceRequestDetails: [],
  serviceRequestList: [],
  isError: false,
  isSuccess: false,
  isSaveSuccess: false,
  showIsSuccess: false,
  isLoading: false,
  message: "",
  buyerCurrentServiceRequest: {},
  sellerCurrentServiceRequest: {},
};

export const listServiceRequests = createAsyncThunk(
  "serviceRequest/listServiceRequests",
  async (payload, thunkAPI) => {
    try {
      const { providerId, serviceType, customerId, customerType } = payload;
      let response;
      if (providerId && serviceType) {
        response = await instance.get(
          `${SLICE_URL}?providerId=${providerId}&serviceType=${serviceType}&verbose=true`,
          config
        );
      } else if (customerId && customerType) {
        response = await instance.get(
          `${SLICE_URL}?customerId=${customerId}&customerType=${customerType}&verbose=true`,
          config
        );
      }
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createServiceRequest = createAsyncThunk(
  "serviceRequest/createServiceRequest",
  async (payload, thunkAPI) => {
    try {
      config.headers.recaptchatoken=payload?.recaptchatoken
      delete payload.recaptchatoken;
      const response = await instance.post(`${SLICE_URL}`, payload, config);
      return response.data;
    } catch (error) {
      let message = error?.response?.data?.errors[0];
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSingleServiceRequest = createAsyncThunk(
  "serviceRequest/getSingleServiceRequest",
  async (payload, thunkAPI) => {
    try {
      const response = await instance.get(`${SLICE_URL}/${payload}`, config);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateServiceRequest = createAsyncThunk(
  "serviceRequest/updateServiceRequest",
  async (payload, thunkAPI) => {
    try {
      document.getElementById("loading-indicator").classList.add("open");
      const { serviceRequestID, formData } = payload;
      const response = await instance.put(
        `${SLICE_URL}/${serviceRequestID}`,
        formData,
        config
      );
      document.getElementById("loading-indicator").classList.remove("open");

      return response.data;
    } catch (error) {
      document.getElementById("loading-indicator").classList.remove("open");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteServiceRequest = createAsyncThunk(
  "serviceRequest/deleteServiceRequest",
  async (payload, thunkAPI) => {
    // payload: serviceID
    try {
      const { serviceRequestID } = payload;
      const response = await instance.delete(
        `${SLICE_URL}/${serviceRequestID}`,
        config
      );
      // thunkAPI.dispatch(listServiceRequests({payload})); // input correct values for payload.
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const serviceRequestSlice = createSlice({
  name: "serviceRequest",
  initialState,
  reducers: {
    resetServiceRequest: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isSaveSuccess = false;
      state.showIsSuccess = false;
      state.isError = false;
      state.message = "";
    },
    changeBuyerCurrentServiceRequest: (state, action) => {
      return {
        ...state,
        buyerCurrentServiceRequest: action?.payload,
      };
    },
    changeSellerCurrentServiceRequest: (state, action) => {
      return {
        ...state,
        sellerCurrentServiceRequest: action?.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listServiceRequests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(listServiceRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.serviceRequestList = action.payload;
        if (
          state.sellerCurrentServiceRequest?.hasOwnProperty(
            "serviceRequestID"
          ) &&
          action.payload?.find(
            (serviceReq) =>
              serviceReq?.serviceRequestID ===
              state.sellerCurrentServiceRequest?.serviceRequestID
          )
        ) {
          state.sellerCurrentServiceRequest = action.payload?.find(
            (serviceReq) =>
              serviceReq?.serviceRequestID ===
              state.sellerCurrentServiceRequest?.serviceRequestID
          );
        }
        if (
          state.buyerCurrentServiceRequest?.hasOwnProperty(
            "serviceRequestID"
          ) &&
          action.payload?.find(
            (serviceReq) =>
              serviceReq?.serviceRequestID ===
              state.buyerCurrentServiceRequest?.serviceRequestID
          )
        ) {
          state.buyerCurrentServiceRequest = action.payload?.find(
            (serviceReq) =>
              serviceReq?.serviceRequestID ===
              state.buyerCurrentServiceRequest?.serviceRequestID
          );
        }
      })
      .addCase(listServiceRequests.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(createServiceRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createServiceRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSaveSuccess = true;
        state.serviceRequestDetails = action.payload;
      })
      .addCase(createServiceRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getSingleServiceRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleServiceRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showIsSuccess = true;
        state.serviceRequestDetails = action.payload;
      })
      .addCase(getSingleServiceRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updateServiceRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateServiceRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSaveSuccess = true;
      })
      .addCase(updateServiceRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteServiceRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteServiceRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSaveSuccess = true;
      })
      .addCase(deleteServiceRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {
  resetServiceRequest,
  changeBuyerCurrentServiceRequest,
  changeSellerCurrentServiceRequest,
} = serviceRequestSlice.actions;

export default serviceRequestSlice.reducer;
