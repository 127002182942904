import React, { Fragment } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { IoMenuOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";

import Images from "../../../assets/uploaded_images.json";
import Cookie from "js-cookie";
const Account = Images["account-header.svg"];

const SideBar = ({ photographerPersonaId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleLogout = () => {
    localStorage.removeItem("activePersonaDetails");
    localStorage.removeItem("authenticatedUser");
    localStorage.removeItem("activePersona");
    localStorage.removeItem("user");
    localStorage.removeItem("currentTokenNotification");
    localStorage.removeItem("virtualAssistantRefLink");
    Cookie.remove('userToken');
    Cookie.remove("activerPersona")
    navigate("/login");
  };

  return (
    <Fragment>
      {["right"].map((anchor) => (
        <Fragment key={anchor}>
          <div className="bell user-menu" onClick={toggleDrawer(anchor, true)}>
            {!isMobile && <img loading="lazy" src={Account} className="icon" alt="" />}
            {isMobile && (
              <IoMenuOutline style={{ color: "#0097A7" }} size={40} />
            )}
          </div>

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            <div className="modal-header">
              <span
                onClick={toggleDrawer(anchor, false)}
                className="closebutton"
              >
                <AiOutlineClose />
              </span>
            </div>
            <div>
              <div className="modal-body">
                <Link
                  to="/photographer/dashboard"
                  className="modal-link"
                  onClick={toggleDrawer(anchor, false)}
                >
                  {" "}
                  {t("Продавачи")}{" "}
                </Link>

                <Link
                  to="/third-party?lawyers"
                  className="modal-link"
                  onClick={toggleDrawer(anchor, false)}
                >
                  {t("Професионалисти")}
                </Link>

                <Link
                  to={`/photographer/my-profile/${photographerPersonaId}`}
                  className="clearfix modal-link"
                  onClick={toggleDrawer(anchor, false)}
                  style={{ textTransform: "capitalize" }}
                >
                  {t("Моят профил")}
                </Link>

                <button
                  className="modal-link register-modal modal-logout"
                  onClick={handleLogout}
                >
                  {" "}
                  {t("Изход")}
                </button>
              </div>
            </div>
          </SwipeableDrawer>
        </Fragment>
      ))}
    </Fragment>
  );
};

export default SideBar;
