import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
    faSearch: {
      float: "left",
      margin: "8px 5px 0 11px",
      color: "#000",
      width: "18px",
      height: "18px",
    },
  });