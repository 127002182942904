import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../config/axiosConfig';
import Cookie from 'js-cookie';
import { setLocalStorageItem } from '../../config/localStorageEncryption';

let initialState = {
    multiprofileDetails: {
        activePersona: null,
        isOpenModal: false,
        showVAEducationVideo:false
    },
}

const getToken = () => Cookie.get('userToken');

export const changeActivePersona = createAsyncThunk(
    'user/switchpersona',
    async (payload, thunkAPI) => {
        try {
            const token = getToken()
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }

            const response = await instance.post('user/switchpersona', payload, config)

            if(response?.data!==undefined){

                const userToken = response?.data.token
                const { userId, email: userEmail, activePersona, familyName, createdAt, isActive, name, phoneNumber, profilePicture,unsubscribedEmailNotifications,userSocketIoRoomId } = response?.data.findUser
                const activePersonaDetails = response?.data?.activePersona
                const findUser = { userId, activePersona, userEmail, familyName, createdAt, isActive, name, phoneNumber, profilePicture,unsubscribedEmailNotifications,userSocketIoRoomId }
                findUser.activePersona = payload?.activePersona;
                Cookie.set('userToken', userToken, { expires: 7 })
                Cookie.set('activePersona', findUser.activePersona)
                setLocalStorageItem('authenticatedUser', findUser)
                setLocalStorageItem('activePersonaDetails', activePersonaDetails)
                window.location.pathname = '/';

                return response

            }else{
                return
            }
            

        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });

const multiprofileSlice = createSlice({
    name: "multiprofile",
    initialState,
    reducers: {
        multiprofileDetails: (state, action) => {
            return {
                ...state,
                multiprofileDetails: {
                    ...state.multiprofileDetails,
                    ...action?.payload
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(changeActivePersona.pending, (state) => {
                state.loading = 'loading'
            })
            .addCase(changeActivePersona.fulfilled, (state, { payload }) => {
                state.loading = 'loaded'
                state.multiprofileDetails = payload.data
            })
            .addCase(changeActivePersona.rejected, (state, action) => {
                const { error } = action.meta.arg
                state.loading = 'rejected'
                state.error = error
                state.multiprofileDetails = []
                console.error(action.error.message)
            });

    }
})

export const { multiprofileDetails } = multiprofileSlice.actions;
export default multiprofileSlice.reducer